<template>
  <v-container style="height: 100%; width: 100%;">
    <v-layout style="height: 100%; width: 100%;" wrap="True">
      <v-row style="height: 80%; width: 100%;">
        <v-spacer></v-spacer>
        <v-col sm="8" style="width: 100%; height: 100%;">
          <v-card class="py-4 install_box" style="width: 100%; height: 100%;" v-if="!loading">
            <!--Setup error bar-->
            <transition name="fade">
              <v-col v-if="error == true" class="ma-0 pa-0 error_bar" sm="12" style="height: 10%; width: 100%;">
                <span class="error_bar_text"><v-icon color='white' style="margin-right: 2%;">mdi-alert-circle-outline</v-icon>Error! Missing Selections</span>
              </v-col>
            </transition>
            <!--Setup the variables selectors-->
            <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;">
              <v-col sm="12">
                  <span class="install_filter_prompt">Choose the measurements you want along the <b>x-axis</b></span>
                  <v-autocomplete class="install_filter_options" v-model="selected_x_variables" :items="available_measurements" item-text="name" item-value="value" multiple></v-autocomplete>
              </v-col>
              <v-col sm="12">
                  <span class="install_filter_prompt">Choose the measurements you want along the <b>y-axis</b></span>
                  <v-autocomplete class="install_filter_options" v-model="selected_y_variables" :items="available_measurements" item-text="name" item-value="value" multiple></v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
          <!--Loading Card-->
          <v-card class="install_box" style="width: 100%; height: 100%;" v-if="loading">
            <v-row style="width: 100%; height: 100%;" align="center" justify="center">
              <v-col cols="auto">
                <v-progress-circular indeterminate color="#0070ff" size="100"></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row style="height: 10%; width: 100%;" v-if="!loading">
        <v-spacer></v-spacer>
        <v-col sm="4">
          <v-btn class="install_actions_text install_action_button" style="width: 40%; margin-left: 30%; margin-right: 30%;" @click="previous_page">Prev</v-btn>
        </v-col>
        <v-col sm="4">
          <v-btn class="install_actions_text install_action_button" style="width: 40%; margin-left: 30%; margin-right: 30%;" @click="finished_install">Next</v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row style="height: 10%; width: 100%;">
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
    // import SmartCurveTitleBar from "../SmartCurveTitleBar";
    import * as datoramaApi from "@/javascript/DatoramaApiConnect.js"
    export default {
        name: "variables",
        data(){
          return {
              available_measurements: [{'name':'test'}],
              loading: true,
              selected_x_variables: [],
              selected_y_variables: [],
              error: false
          }
        },
        async mounted() {
          let measurements = await this.get_measurement_options();
          this.available_measurements = measurements;
          await this.sleep(1000);
          this.loading = false;
        },
        methods: {
          get_measurement_options: async function() {
              let measurements = await datoramaApi.getMeasurments();
              let measurement_map = [];
              let measurement_name = "";
              let measurement_key = "";
              for(measurement_key of Object.keys(measurements)){
                measurement_name = measurements[measurement_key]['displayName'];
                measurement_map.push({'value': measurement_key, 'name': measurement_name});
              }
              return measurement_map;
          },
          finished_install: function(){
              let x_vars = this.selected_x_variables;
              let y_vars = this.selected_y_variables;
              if(x_vars != null && y_vars != null && x_vars.length > 0 && y_vars.length > 0){
                let variables_map = {'Variables': {'x_vars': x_vars, 'y_vars': y_vars}};
                this.$emit('install_page_finished', variables_map);
              }
              else{
                  this.error = true;
                  this.kill_error(3000);
              }
          },
          previous_page: function(){
              this.$emit('install_previous_page');
          },
          kill_error: async function(ms){
              await this.sleep(ms);
              this.error = false;
          },
          sleep: function(ms){
              return new Promise(resolve => setTimeout(resolve, ms));
          }
        }
    }
</script>

<style scoped>

</style>
