<!--
Created on Sat Feb 1 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the Graph Legend vue component of the SmartCurve Application.
This component is used to build the legend for the graph.
-->
<template>
    <v-card style="width: 80%; height: 80%; top: 10%; left: 10%;" flat>
        <v-card-title class="legend_title">Legend</v-card-title>
        <v-simple-table style="height: 80%" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <th class="legend_header">Dataset Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="line in datasetNameColors" :key="line.key">
                        <th>
                            <v-icon v-if="line.type=='point'" :color="line.color">mdi-circle-medium</v-icon>
                            <v-icon v-else-if="line.type=='line'" :color="line.color">mdi-minus</v-icon>
                        </th>
                        <th class="legend_text">{{line.name}}</th>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    export default {
        name: "GraphLegend",
        props: [
            'dataController',
            'loading',
            'multi'
        ],
        watch: {
            loading: {
                handler(){
                    if(this.dataController != null){
                        this.build_color_name_list();
                    }
                }
            },
            multi: {
                handler(){
                    if(this.dataController != null){
                        this.build_color_name_list();
                    }
                }
            }
        },
        data(){
            return {
                datasetNameColors: [],
                colors: ['#0070ff','#ff63a7','#0070ff','#ff63a7','#EE6352','#59CD90','#EE6352','#59CD90','#FAC05E','#A53860','#FAC05E','#A53860']
            }
        },
        mounted() {
            if(this.dataController != null){
                this.build_color_name_list();
            }
        },
        methods: {
            /*
            The build_color_name_list function is used to build the legend associated with the graph setting the name of the
            dataset and curves to their respective colors
             */
            build_color_name_list: function(){
                this.datasetNameColors = [];
                let datasets = this.dataController.get_dataset_names();
                let j = 0;
                let num_datasets = 0;
                if(this.multi){
                    num_datasets = datasets.length;
                }
                else{
                    datasets = [this.dataController.curDataName];
                    num_datasets = 1;
                }
                for(let i = 0; i < num_datasets; i++){
                    let data = {'name': datasets[i], 'color': this.colors[i + j], 'type': 'point'};
                    let outlier = {'name': datasets[i] + ' Outliers', 'color': this.colors[i + j + 1], 'type': 'point'};
                    let smart_curve = {'name': datasets[i] + ' SmartCurve', 'color': this.colors[i + j + 2], 'type': 'line'};
                    let prediction = {'name': datasets[i] + ' Prediction Line', 'color': this.colors[i + j + 3], 'type': 'line'};
                    this.datasetNameColors.push(data);
                    this.datasetNameColors.push(outlier);
                    this.datasetNameColors.push(smart_curve);
                    this.datasetNameColors.push(prediction);
                    j += 3;
                }
            }
        }
    }
</script>