/*
The Data object is used to encapsulate raw data pulled from datorama, the regression line built using the docma algorithm, and outliers.
It also encapsulates information about the filters and measurments used to build the curve and obtain the data.
 */
export default class Data{
    constructor(xyData = [], outliers=[]){
        this._dataName = "";
        this._xyData = xyData;
        this._xyDataOutliers = outliers;
        this._regressionLine = [];
        this._predictionLine = [];
        this._error = 0;
        this._curveStrength = 0;
        this._filterDict = {};
        this._filterOrder = [];
        this._independentVar = null;
        this._dependentVar = null;
        this._dateRange = [];
        this._cluster = null;
        this._outliersRemoved = true;
        this._smartCurveBuilt = true;
        this._outlierSensitivity = 5;
    }
    /*
    Getter and Setters for the data objects run configuration
    The outlier sensitivity, weather or not outliers have been removed, as well as weather or not a smartcurve has been built
     */
    set outlierSensitivity(sensitivity){
        this._outlierSensitivity = sensitivity;
    }
    get outlierSensitivity(){
        return this._outlierSensitivity;
    }
    set outliersRemoved(removed){
        this._outliersRemoved = removed;
    }
    get outliersRemoved(){
        return this._outliersRemoved;
    }
    set smartCurveBuilt(built){
        this._smartCurveBuilt = built;
    }
    get smartCurveBuilt(){
        return this._smartCurveBuilt;
    }
    /*
    Getter And Setters For the data objects filters and measurements
     */
    set dataName(name){
        this._dataName = name;
    }
    get dataName(){
        return this._dataName;
    }
    set filterDict(filterDict){
        if(!this.deepMapEqualityCheck(this._filterDict,filterDict)) {
            this._filterDict = filterDict;
        }
    }
    get filterDict(){
        return this._filterDict;
    }
    set filterOrder(filterOrder){
        if(!this.deepListEqualityCheck(this._filterOrder,filterOrder)) {
            this._filterOrder = filterOrder;
        }
    }
    get filterOrder(){
        return this._filterOrder;
    }
    set cluster(cluster) {
        if(cluster !== this._cluster) {
            this._cluster = cluster;
        }
    }
    get cluster(){
        return this._cluster;
    }
    set independentVar(variable) {
        if(variable !== this._independentVar) {
            this._independentVar = variable;
        }
    }
    get independentVar(){
        return this._independentVar;
    }
    set dependentVar(variable){
        if(variable !== this._dependentVar) {
            this._dependentVar = variable;
        }
    }
    get dependentVar(){
        return this._dependentVar
    }
    set dateRange(date_range){
        if(!this.deepListEqualityCheck(this._dateRange,date_range)){
            this._dateRange = date_range;
        }
    }
    get dateRange(){
        return this._dateRange;
    }
    /*
    Getter and Setters for the Data Object Data
     */
    set xyData(xyData){
        this._xyData = xyData;
    }
    get xyData(){
        return this._xyData;
    }
    getXValues(){
        let x_values = [];
        for(var row of this._xyData){
            var x = row[0];
            x_values.push(x);
        }
        return x_values;
    }
    getYValues(){
        let y_values = [];
        for(var row of this._xyData){
            var y = row[1];
            y_values.push(y);
        }
        return y_values;
    }
    set error(error){
        this._curveStrength = error * 100;
        let err = -1 * (error - 1);
        let max_v = this.getMax('data','y');
        let min_v = this.getMin('data','y');
        err = err * (max_v - min_v);
        this._error = err;
    }
    get error(){
        if(this._regressionLine.length == 0){
            return 'N/A'
        }
        else {
            return this._error;
        }
    }
    get curveStrength(){
        return this._curveStrength;
    }
    setRegressionLine(data){
        let xs = data['x_data'];
        let ys = data['y_data'];
        this._regressionLine = [];
        for(let i = 0; i < xs.length; i++){
            this._regressionLine.push([xs[i],ys[i]])
        }
    }
    get regressionLine(){
        return this._regressionLine
    }
    setPredictionLine(data){
        let xs = data['x_data'];
        let ys = data['y_data'];
        this._predictionLine = [];
        for(let i = 0; i < xs.length; i++){
            this._predictionLine.push([xs[i],ys[i]]);
        }
    }
    get predictionLine(){
        return this._predictionLine;
    }
    setOutliers(outliers){
        let i = 0;
        for(let outlier of outliers){
            i = 0;
            for(let row of this._xyData){
                if(outlier[0] == row[0] && outlier[1] == row[1]){
                    this._xyData.splice(i,1);
                    break;
                }
                i ++;
            }
        }
        this._xyDataOutliers = outliers;
    }
    get xyDataOutliers(){
        return this._xyDataOutliers;
    }
    format_json_output(){
        let data = {
            'x_data': this.getXValues(),
            'y_data': this.getYValues()
        };
        return data;
    }
    /*
    Data object statistics functions
     */
    getLength(){
        return this._xyData.length;
    }
    getNumOutliers(){
        if(this._xyData.length == 0){
            return 'N/A';
        }
        else{
            return this._xyDataOutliers.length;
        }
    }
    getMin(data_part, variable){
        let data = [];
        if(data_part == 'data'){
            data = this._xyData;
        }
        else if(data_part == 'outliers'){
            data = this._xyData.concat(this._xyDataOutliers);
        }
        else if(data_part == 'smartcurve'){
            data = this._xyData.concat(this._predictionLine);
        }
        else if(data_part == 'no_outliers'){
            data = this._xyData.concat(this._predictionLine);
        }
        else if(data_part == 'no_smartcurve'){
            data = this._xyData.concat(this._xyDataOutliers);
        }
        else if(data_part == 'all'){
            data = this._xyData.concat(this._xyDataOutliers).concat(this._predictionLine);
        }
        let idx = 0;
        if(variable == 'y'){
            idx = 1;
        }
        let minChamp = null;
        for(var row of data){
            var val = row[idx];
            if(minChamp == null){
                minChamp = val;
            }
            else if(val < minChamp){
                minChamp = val;
            }
            else{
                continue;
            }
        }
        return minChamp;
    }
    getMax(data_part, variable){
        let data = [];
        if(data_part == 'data'){
            data = this._xyData;
        }
        else if(data_part == 'outliers'){
            data = this._xyData.concat(this._xyDataOutliers);
        }
        else if(data_part == 'smartcurve'){
            data = this._xyData.concat(this._predictionLine);
        }
        else if(data_part == 'no_outliers'){
            data = this._xyData.concat(this._predictionLine);
        }
        else if(data_part == 'no_smartcurve'){
            data = this._xyData.concat(this._xyDataOutliers);
        }
        else if(data_part == 'all'){
            data = this._xyData.concat(this._xyDataOutliers).concat(this._predictionLine);
        }
        let idx = 0;
        if(variable == 'y'){
            idx = 1;
        }
        let maxChamp = null;
        for(var row of data){
            var val = row[idx];
            if(maxChamp == null){
                maxChamp = val;
            }
            else if(val > maxChamp){
                maxChamp = val;
            }
            else{
                continue;
            }
        }
        return maxChamp;
    }
    /*
    Helper functions
     */
    deepListEqualityCheck(lst1,lst2){
        if(lst1 == null || lst2 == null){
            return false;
        }
        if(lst1.length !== lst2.length){
            return false;
        }
        for(let i = 0; i < lst1.length; i++){
            if(!lst2.includes(lst1[i])){
                return false;
            }
        }
        return true
    }
    deepMapEqualityCheck(obj1,obj2){
        if(obj1 == null || obj2 == null){
            return false;
        }
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        if(keys1.length !== keys2.length){
            return false;
        }
        for (let key of keys1) {
            let val1 = obj1[key];
            let val2 = obj2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (areObjects && !this.deepMapEqualityCheck(val1, val2) || !areObjects && val1 !== val2) {
                return false;
            }
        }
        return true;
    }
    isObject(object) {
        return object != null && typeof object === 'object';
    }
}