export default class TreeNode {
    constructor(val,nodesAboveList=[],nodesBelowList=[]){
        this._val = val;
        this._above = nodesAboveList;
        this._below = nodesBelowList;
    }
    addNodeToAboveList(node){
        if(!this.containsAbove(node.val)) {
            this._above.push(node);
        }
    }
    addNodeToBelowList(node){
        if(!this.containsNode(node.val)) {
            this._below.push(node);
        }
    }
    containsNode(node_value){
        for(let idx = 0; idx < this._below.length; idx ++){
            if(this._below[idx].val == node_value){
                return true;
            }
        }
        return false;
    }
    containsAbove(node_value){
        for(let idx = 0; idx < this._above.length; idx ++){
            if(this._above[idx].val == node_value){
                return true;
            }
        }
        return false;
    }
    getNodeBelow(node_value){
        for(let idx = 0; idx < this._below.length; idx ++){
            if(this._below[idx].val == node_value){
                return this._below[idx];
            }
        }
        return false;
    }
    getNodeAbove(node_value){
        for(let idx = 0; idx < this._above.length; idx ++){
            if(this._above[idx].val == node_value){
                return this._above[idx];
            }
        }
        return false;
    }
    get val(){
        return this._val;
    }
    get below(){
        return this._below;
    }
    get above(){
        return this._above;
    }
    getjsonRepresentation(){
        let json_dict = {};
        json_dict[this._val] = {'below': []};
        for(let idx = 0; idx < this._below.length; idx ++){
            json_dict[this._val]['below'].push(this._below[idx].getjsonRepresentation());
        }
        return json_dict;
    }
}
