import TreeNode from './TreeNode.js'
export default class FilterTree{
    constructor(order){
        this._order = order;
        this._nodesAtLevel = {};
        for(let treeLevel of this._order){
            this._nodesAtLevel[treeLevel] = []
        }
        this._root = new TreeNode(null)
    }
    //The val array is a 2D array where the val for each spot in the order is along the x
    //Along the y is the various combinations of vals in the order
    buildTreeFrom2DvalArray(valArray){
        let curNode = this._root;
        let order_level = this._order[0];
        for(let row of valArray){
            curNode = this._root;
            for(let idx = 0; idx < row.length; idx ++){
                order_level = this._order[idx];
                if(curNode.containsNode(row[idx])){
                    curNode = curNode.getNodeBelow(row[idx]);
                }
                else{
                    // let newNode = this.checkifValueExistsAtLevel(row[idx],order_level);
                    // if(newNode == false) {
                    let newNode = new TreeNode(row[idx], [curNode], []);
                    this._nodesAtLevel[order_level].push(newNode);
                    // }
                    // else{
                    //     newNode.addNodeToAboveList(curNode);
                    // }
                    curNode.addNodeToBelowList(newNode);
                    curNode = newNode;
                }

            }
        }
    }
    buildTreeFromJsonRepresentation(jsonRep){
        let curNode = this._root;
        let cur_below = jsonRep[null]['below'];
        this.recursive_build(curNode,0,cur_below);
    }
    recursive_build(curNode,next_level,below){
        for(let b of below){
            let val = Object.keys(b)[0];
            let newNode = new TreeNode(val, [curNode], []);
            this._nodesAtLevel[this._order[next_level]].push(newNode);
            if(!curNode.containsNode(val)) {
                curNode.addNodeToBelowList(newNode);
            }
            this.recursive_build(newNode,next_level+1,b[val]['below']);
        }
    }
    checkifValueExistsAtLevel(value,level){
        let discovered_nodes = [];
        let nodes = this._nodesAtLevel[level];
        for(let node of nodes){
            if(node.val == value){
                discovered_nodes.push(node);
            }
        }
        if(discovered_nodes.length > 0){
            return discovered_nodes;
        }
        else{
            return false;
        }
    }
    //Get a value map above and below values
    getValueMapAboveAndBelowValueAtOrder(value_map){
        let lowest = this.getOrderLowestFromValMap(value_map);
        let highest = this.getOrderHighestFromValMap(value_map);
        let direct_above = null;
        let direct_below = null;
        if(lowest == highest){
            //pass
        }
        else if((highest - lowest) == 1){
            direct_above = value_map[this._order[lowest]];
            direct_below = value_map[this._order[highest]];
        }
        else{
            console.log('Gap');
        }
        let below = this.getValueMapBelow(value_map,direct_above);
        let above = this.getValueMapAbove(value_map,direct_below);
        let combined = Object.assign({},below,above);
        return combined;
    }
    getOrderHighestFromValMap(value_map){
        let highest_val_in_order = -1;
        for(let i = this._order.length-1; i > -1; i --){
            if(value_map[this._order[i]].length > 0){
                highest_val_in_order = i;
                break;
            }
        }
        return highest_val_in_order;
    }
    getOrderLowestFromValMap(value_map){
        let lowest_val_in_order = -1;
        for(let i = 0; i < this._order.length; i ++){
            if(value_map[this._order[i]].length > 0){
                lowest_val_in_order = i;
                break;
            }
        }
        return lowest_val_in_order;
    }
    getValueMapAbove(value_map,direct_below=null){
        let lowest_val_in_order = 0;
        for(let i = 0; i < this._order.length; i ++){
            if(value_map[this._order[i]].length > 0){
                lowest_val_in_order = i;
                break;
            }
        }
        let aboveMap = {};
        let curNodes = [];
        for(let val of value_map[this._order[lowest_val_in_order]]){
            let newNode = this.checkifValueExistsAtLevel(val,this._order[lowest_val_in_order]);
            if(newNode){
                curNodes = curNodes.concat(newNode);
            }
        }
        if(direct_below){
            let tmp_nodes = [];
            for(let node of curNodes){
                for(let val of direct_below){
                    if(node.containsNode(val)){
                        tmp_nodes.push(node);
                        break;
                    }
                }
            }
            curNodes = tmp_nodes;
        }
        for(let j = lowest_val_in_order-1; j > -1; j--){
            let tmp = [];
            for (let cur of curNodes) {
                tmp = tmp.concat(cur.above)
            }
            let tmp_vals = [];
            for(let t of tmp){
                if(t.val != null && t.val != "null") {
                    tmp_vals.push(t.val);
                }
            }
            aboveMap[this._order[j]] = tmp_vals.filter((v, i, a) => a.indexOf(v) === i);
            curNodes = tmp;
        }
        return aboveMap;
    }
    getValueMapBelow(value_map,direct_above=null){
        let highest_val_in_order = 0;
        for(let i = this._order.length-1; i > -1; i --){
            if(value_map[this._order[i]].length > 0){
                highest_val_in_order = i;
                break;
            }
        }
        let belowMap = {};
        let curNodes = [];
        for(let val of value_map[this._order[highest_val_in_order]]){
            let newNode = this.checkifValueExistsAtLevel(val,this._order[highest_val_in_order]);
            if(newNode){
                curNodes = curNodes.concat(newNode);
            }
        }
        if(direct_above){
            let tmp_nodes = [];
            for(let node of curNodes){
                for(let val of direct_above){
                    if(node.containsAbove(val)){
                        tmp_nodes.push(node);
                        break;
                    }
                }
            }
            curNodes = tmp_nodes;
        }
        for(let j = highest_val_in_order+1; j < this._order.length; j++){
            let tmp = [];
            for (let cur of curNodes) {
                tmp = tmp.concat(cur.below);
            }
            let tmp_vals = [];
            for(let t of tmp){
                if(t.val != null && t.val != "null") {
                    tmp_vals.push(t.val);
                }
            }
            belowMap[this._order[j]] = tmp_vals.filter((v, i, a) => a.indexOf(v) === i);
            curNodes = tmp;
        }
        return belowMap;
    }
    checkIfNodeInArray(arr,node){
        for(let n of arr){
            if(n.val == node.val){
                return true;
            }
        }
        return false;
    }
    getjsonRepresentation(){
        let tree_dict = this._root.getjsonRepresentation();
        let response = {'tree': tree_dict, 'order': this._order};
        return response;
    }
}