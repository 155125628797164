/*
Created on Sat Feb 11 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the script for calling the Docma servers
*/
import sdk from '@datorama/sdk'
// import * as DA from '@datorama/sdk'
var getMeasurmentsApiEndpoint = '/v1/workspaces/86213/measurements?type=type';
var getDimensionsApiEndpoint = '/v1/workspaces/86213/dimensions?type=dimensionType';
var getJWTTokenApiEndpoint = 'v1/signatures';
/*
The getMeasurments function is used to get a list of the measurments associated with a users account
params:
-------
-------
return:
-------
success: a dictionary of measurments from the users account
error: the error message from the datoramaApi
-------
 */
export function getMeasurments(){
    var apiCall = getMeasurmentsApiEndpoint;
    return new Promise((resolve, reject) => {
        sdk.api.get(apiCall).then(res=>{
            var measurments = res.data;
            resolve(measurments)
        }).catch(error =>{
            reject(error)
        });
    });
}
/*
The getDimensions function is used to get a list of the dimensions associated with a users account
params:
-------
-------
return:
-------
success: a dictionary of dimensions from the users account
error: the error message from the datoramaApi
-------
 */
export function getDimensions(){
    var apiCall = getDimensionsApiEndpoint;
    return new Promise((resolve,reject) => {
        sdk.api.get(apiCall).then(res=>{
            var dimensions = res.data;
            resolve(dimensions);
        }).catch(error=>{
            reject(error);
        })
    })
}
/*
The getJWTToken function is used to get a bearer token to send to the smartcurve api for verification
params:
-------
-------
return:
-------
success: a datorama token
error: the error message from the datoramaApi
-------
 */
export function getJWTToken(){
    var apiCall = getJWTTokenApiEndpoint;
    return new Promise((resolve,reject)=> {
        sdk.api.post(apiCall).then(res=>{
            var token = res.data;
            resolve(token['signature']);
        }).catch(error=>{
            reject(error);
        })
    })
}
