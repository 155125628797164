/*
Created on Sat Feb 11 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the script for building, running, and interpretting a query on the datorama servers
*/
import sdk from '@datorama/sdk'
export default class DatoramaQuery{
    constructor(){
        this._queryMap = {
            "workspaceId": "86213",
            "dateRange": "CUSTOM",
            "startDate": "",
            "endDate": "",
            "measurements": [],
            "dimensions": [],
            "groupDimensionFilters": [],
            "stringDimensionFilters": [],
            "stringDimensionFiltersOperator": "AND",
            "numberDimensionFiltersOperator": "AND",
            "numberMeasurementFilter": [],
            "sortBy": "",
            "sortOrder": "DESC",
            "topResults": "100",
            "groupOthers": true,
            "topPerDimension": false,
            "totalDimensions": []
        };
        this._desiredDimensions = [];
        this._dimensionFilters = [];
        this._measurments = [];
        this._startDate = '1970-01-01';
        this._endDate = '1970-01-01';
    }
    get queryMap(){
        return this._queryMap;
    }
    set startDate(startDate){
        this._startDate = startDate;
    }
    set endDate(endDate){
        this._endDate = endDate;
    }
    /*
    The add_dimension_filter_to_query function is used to add a dimension filter to a specific query
    params:
    -------
    dimension: the dimension for the filter
    values: a list of values to filter on
    -------
     */
    add_dimension_filter_to_query(dimension, values){
        let filter_query = {
            "vals": [],
            "dimension": "",
            "operator": "IN"
        };
        filter_query["vals"] = values;
        filter_query["dimension"] = dimension;
        this._dimensionFilters.push(filter_query);
    }
    /*
    The add_measurment_to_query function is used to add a desired measurement in your query
    params:
    -------
    measurment: The measurment to add to the query
    -------
     */
    add_measurment_to_query(measurment){
        let measurment_query = {
            "name": ""
        };
        measurment_query["name"] = measurment;
        this._measurments.push(measurment_query);
    }
    /*
    The add_dimension_to_query function is used to add a desired dimension in your query
    params:
    -------
    dimension: The dimension to add to the query
    -------
     */
    add_dimension_to_query(dimension){
        this._desiredDimensions.push(dimension);
    }
    /*
    The build_query function is used to build a datorama query from the set variables
     */
    build_query(){
        this._queryMap['groupDimensionFilters'] = this._dimensionFilters;
        this._queryMap['dimensions'] = this._desiredDimensions;
        this._queryMap['measurements'] = this._measurments;
        this._queryMap['startDate'] = this._startDate;
        this._queryMap['endDate'] = this._endDate;
    }
    /*
    The run_query function is used to run a query and interpret the response
    params:
    -------
    desired_variables: the variables to interpret the query
    include_null: a boolean to decide which interpret function to use
    -------
    return:
    -------
    the interpreted datorama query
    -------
     */
    async run_query(desired_variables,include_null=false){
        this.build_query();
        let query_data = await this.datorama_query_api();
        console.log(query_data);
        let interpreted_query = null;
        if(include_null){
            interpreted_query = this.interpretQueryIncludeNull(query_data,desired_variables);
        }
        else{
            interpreted_query = this.interpretQuery(query_data,desired_variables);
        }
        return interpreted_query;
    }
    /*
    The datorama_query_api function is used to run the query endpoint of the datorama api
    params:
    -------
    -------
    return:
    -------
    either a promise resolve with the returned data or a promise reject with message rejected
    -------
     */
    datorama_query_api(){
        let query = this._queryMap;
        return new Promise(function(resolve,reject){
            sdk.api.post('/v1/query?useSystemNames=true',query).then(res=>{
                resolve(res.data);
            }).catch(error=>{
                console.log(error);
                reject("rejected")
            })
        });
    }
    /*
    The getPositionsMap function is used to build a dictionary where the key is the idx of the desiredVariable and
    the value is the corresponding desiredVariable.
    params:
    -------
    queryResponseHeaders: the header of the queryResponse to build the dictionary on
    desired_variables: a list of dimensions to build the dictionary on
    -------
    return:
    -------
    The built position map
    -------
     */
    getPositionsMap(queryResponseHeaders,desired_variables){
        let positionMap = [];
        for(let desired_var of desired_variables){
            if(queryResponseHeaders.includes(desired_var)){
                let idx = queryResponseHeaders.indexOf(desired_var);
                positionMap.push({'loc': idx, 'var': desired_var});
            }
        }
        return positionMap;
    }
    /*
    The interpretQuery function is used to to a datorama query into a list
    params:
    -------
    queryResponse: the query response from the datorama api
    desiredVariables: a list of dimensions to pull from the query
    -------
    return:
    -------
    a list of interpreted data from the query response, if there is a single des-ired variable then a 1D list is returned,
    if there is more than a single desired variable a 2D list is returned
    -------
     */
    interpretQuery(queryResponse,desiredVariables){
        let interpretedData = [];
        let positionsMap = this.getPositionsMap(queryResponse['queryResponseData']['headers'],desiredVariables);
        for(var row of queryResponse['queryResponseData']['rows']){
            let data_row = [];
            for(var pos of positionsMap) {
                data_row.push(row[pos['loc']]);
            }
            interpretedData.push(data_row);
        }
        let reinterpretedData = [];
        for(let row of interpretedData){
            if(row.length == 1){
                if(row[0] != null){
                    reinterpretedData.push(row[0]);
                }
            }
            else{
                let containsNull = false;
                for(let val of row){
                    if(val == null || val == "null" || val == "Others"){
                        containsNull = true;
                    }
                }
                if(!containsNull){
                    reinterpretedData.push(row);
                }
            }
        }
        return reinterpretedData;
    }
    /*
    The interpretQueryIncludeNull function is used to to a datorama query into a list and include null values
    params:
    -------
    queryResponse: the query response from the datorama api
    desiredVariables: a list of dimensions to pull from the query
    -------
    return:
    -------
    a list of interpreted data from the query response, if there is a single desired variable then a 1D list is returned,
    if there is more than a single desired variable a 2D list is returned
    -------
     */
    interpretQueryIncludeNull(queryResponse,desiredVariables){
        let interpretedData = [];
        let positionsMap = this.getPositionsMap(queryResponse['queryResponseData']['headers'],desiredVariables);
        for(var row of queryResponse['queryResponseData']['rows']){
            let data_row = [];
            for(var pos of positionsMap) {
                data_row.push(row[pos['loc']]);
            }
            interpretedData.push(data_row);
        }
        return interpretedData;
    }
}