<!--
Created on Sat Feb 1 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the Filter Selector vue component of the SmartCurve Application.
This component is used for a specific filter in the filter bar.
-->
<template>
    <div style="height:100%; width:100%;">
        <v-select v-model="selected" :items="options" :label="filter_name" multiple @change="selection_update($event)">
            <template v-slot:prepend-item>
                <v-list-item @click="select_all_options()">
                    <v-list-item-action>
                        <v-icon v-if="selected.length == 0">mdi-checkbox-blank-outline</v-icon>
                        <v-icon v-if="selected.length < options.length && selected.length > 0">mdi-minus-box</v-icon>
                        <v-icon v-if="selected.length == options.length" >mdi-close-box</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-if="selected.length < options.length">Select All</v-list-item-title>
                        <v-list-item-title v-if="selected.length == options.length">Unselect All</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-2"></v-divider>
            </template>
            <template v-slot:selection="{item,index}">
                <v-chip v-if="index === 0"><span>{{item}}</span></v-chip>
                <v-chip v-if="index === 1"><span>{{item}}</span></v-chip>
                <span v-if="index === 2">(+{{selected.length - 2}} others)</span>
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: "FilterSelector",
        props: [
            'filter_name',
            'filter_options',
            'selected_filter'
        ],
        mounted(){
            this.options = [...this.filter_options];
            if (this.selected_filter != null) {
                this.selected = this.selected_filter;
            }
        },
        watch: {
            selected_filter: {
                handler: function() {
                    if (this.selected_filter != null) {
                        this.selected = this.selected_filter;
                    }
                },
                deep: true
            },
            filter_options: {
                immediate: true,
                deep: true,
                handler: function() {
                    this.options = [...this.filter_options];
                },
            }
        },
        data(){
            return {
                options: [],
                selected: []
            }
        },
        methods: {
            select_all_options: function (){
                this.$nextTick(() => {
                    if(this.selected.length == this.options.length){
                        this.selected = [];
                    }
                    else if(this.selected.length > 0 && this.selected.length < this.options.length){
                        this.selected = this.options;
                    }
                    else{
                        this.selected = this.options;
                    }
                    this.selection_update(this.selected);
                });
            },
            selection_update: function(update){
                let filter = this.filter_name;
                let value_map = {};
                value_map[filter] = update;
                this.$emit('selectionUpdate', value_map);
            }
        }
    }
</script>

<style scoped>

</style>