<template>
    <v-container color="black">
        <v-layout text-center>
            <v-flex lg6 class="mx-auto">
                <v-card outlined class="pa-4 my-10">
                    <v-form>
                        <v-text-field outlined type="text" placeholder="Email" v-model="email" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-text-field outlined type="password" placeholder="Password" v-model="password" class="ma-4 loginFormTextStyle" color="primary" @keyup.enter="login"></v-text-field>
                        <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="primary" @click="login">Log In</v-btn>
                    </v-form>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import firebase from 'firebase';
    import request from 'request';
    // import axios from 'axios';
    export default {
        name: 'Login',
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            login: async function(){
                var firebaseAuth = await this.firebase_login_auth(this.email,this.password);
                if(firebaseAuth){
                    var apiToken = await this.getApiToken(this.email,this.password);
                    console.log(apiToken)
                    this.$emit('newApiToken', apiToken);
                    this.$router.replace('run');
                }
            },
            firebase_login_auth: function(email, password) {
                return new Promise((resolve, reject) => {
                    firebase.auth().signInWithEmailAndPassword(email, password).then(
                        (user) => {
                            firebase.auth.currentUser = user;
                            resolve(true);
                        },
                        (err) => {
                            alert('Oops. ' + err.message);
                            reject()
                        }
                    );
                });
            },
            getApiToken: function(email,password){
                const url = 'https://api.docmasmartcurve.ca/auth/login';
                // const port = 80;
                // const path = '/login';
                var authCredentials = {
                    'username': email,
                    'password': password
                };
                // var options = {
                //   hostname: host,
                //   port: port,
                //   path: path,
                //   method: 'GET',
                //   authorization: authCredentials
                // };
                // console.log(optis
                return new Promise((resolve, reject) => {
                    request.get(url,{'auth': authCredentials},function(error,res,body){
                        if(error){
                            reject(error)
                        }
                        console.log(body);
                        var token = JSON.parse(body);
                        token = token["token"];
                        resolve(token)
                    });
                    // axios.get(url,{auth: authCredentials}).then((res) => {
                    //     resolve(res.data['token'])
                    // }).catch((error) => {
                    //     reject(error)
                    // })
                })
            }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Poppins:400');
    /*.loginFormTextStyle{*/
    /*font-family: 'Poppins' !important;*/
    /*}*/
</style>