<template>
    <v-container style="height: 100%; width: 100%;">
        <v-layout style="height: 100%; width: 100%;" wrap="True">
            <v-row style="height: 80%; width: 100%;" v-if="!loading">
                <v-spacer></v-spacer>
                <v-col sm="8" style="height: 100%; width: 100%;">
                    <v-card class="py-4 install_box" style="height: 100%; width: 100%;">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%; overflow-y: scroll;" justify="center">
                            <v-col sm="9" style="height: 40%;">
                                <v-btn class="install_type_selector" style="height: 100%; width:100%;" @click="selected_filter_setup('Marketing')">Marketing Setup</v-btn>
                            </v-col>
                            <v-col sm="9" style="height: 40%;">
                                <v-btn class="install_type_selector" style="height: 100%; width:100%;" @click="selected_filter_setup('Custom')">Custom Setup</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row style="height: 20%; width: 100%;">
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
    // import SmartCurveTitleBar from "../SmartCurveTitleBar";
    export default {
        name: "filter_setup_selector",
        components: {
            // SmartCurveTitleBar
        },
        methods: {
            selected_filter_setup: function(selected_filter_setup){
                this.$emit('install_page_finished', selected_filter_setup);
            }
        }
    }
</script>

<style scoped>

</style>