/*
Created on Sat Feb 11 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the script for calling the Docma servers
*/
import jQuery from 'jquery'
/*
The test_auth function is used to hit a test endpoint on the docma servers
params:
-------
jwt: the datorama web token
-------
return:
-------
success: the message for the test endpoint
error: the error message
-------
 */
export function test_auth(jwt){
    return new Promise((resolve,reject) => {
        jQuery.ajax({
            url: 'https://api.docmasmartcurve.ca/test/auth',
            type: 'POST',
            json: {content: 'testing test'},
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "BEARER " + jwt);
            },
            success: function (response) {
                resolve(response)
            },
            error: function(error){
                reject(error)
            }
        })
    })
}
/*
The smart_curve function is used to call the smart curve endpoint on the Docma servers which will generate the regression analysis
and prediction line for a specified dataset
params:
-------
jwt: the datorama token
data: the data to send to the docma servers
-------
return:
-------
success: the docma server response containing the data for the regression analysis and prediction
error: the error message
-------
 */
export function smart_curve(jwt,data){
    return new Promise((resolve,reject) => {
        jQuery.ajax({
            url: 'https://api.docmasmartcurve.ca/smartcurve',
            type: 'POST',
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "BEARER " + jwt);
                xhr.setRequestHeader("Content-Type", 'application/json')
            },
            success: function (response) {
                resolve(response)
            },
            error: function(error){
                reject(error)
            }
        })
    })
}
/*
The smart_curve_demo function is used to call the smart curve endpoint on the Docma servers which will generate the regression analysis
and prediction line for a specified dataset for the docma demo mode
params:
-------
data: the data to send to the docma servers
-------
return:
-------
success: the docma server response containing the data for the regression analysis and prediction
error: the error message
-------
 */
export function smart_curve_demo(data,token){
    return new Promise((resolve,reject) => {
        jQuery.ajax({
            url: 'https://api.docmasmartcurve.ca/demo/smartcurve?token='+token,
            type: 'POST',
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Content-Type", 'application/json')
            },
            success: function (response) {
                resolve(response)
            },
            error: function(error){
                reject(error)
            }
        })
    })
}
/*
The remove_outliers function is used to call the removeOutliers endpoint on the Docma servers which will remove any detected outliers
from a specified dataset
params:
-------
jwt: the datorama token
data: the data to send to the docma servers
-------
return:
-------
success: the docma server response containing the data points that are considered outliers
error: the error message
-------
 */
export function remove_outliers(jwt,data){
    return new Promise((resolve,reject) => {
        jQuery.ajax({
            url: 'https://api.docmasmartcurve.ca/smartcurve/removeOutliers',
            type: 'POST',
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "BEARER " + jwt);
                xhr.setRequestHeader("Content-Type", 'application/json')
            },
            success: function (response) {
                resolve(response)
            },
            error: function(error){
                reject(error)
            }
        })
    })
}
/*
The remove_outliers_demo function is used to call the removeOutliers endpoint on the Docma servers which will remove any detected outliers
from a specified dataset for the docma demo
params:
-------
data: the data to send to the docma servers
-------
return:
-------
success: the docma server response containing the data points that are considered outliers
error: the error message
-------
 */
export function remove_outliers_demo(data,token){
    return new Promise((resolve,reject) => {
        jQuery.ajax({
            url: 'https://api.docmasmartcurve.ca/demo/removeOutliers?token='+token,
            type: 'POST',
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Content-Type", 'application/json')
            },
            success: function (response) {
                resolve(response)
            },
            error: function(error){
                reject(error)
            }
        })
    })
}
