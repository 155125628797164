<template>
  <v-container style="height: 100%; width: 100%;">
    <v-layout style="height: 100%; width: 100%;" wrap="True">
      <v-row style="height: 80%; width: 100%; position: relative">
        <v-spacer></v-spacer>
        <v-col sm="8" style="height: 100%; width: 100%;">
          <v-card class="py-4 install_box" style="height: 100%; width: 100%;" v-if="!loading">
            <!--Setup error bar-->
            <transition name="fade" style="z-index:2;">
              <v-col v-if="error == true" class="ma-0 pa-0 error_bar" sm="12" style="height: 10%; width: 100%; position: absolute; top: 0; left: 0;">
                  <span class="error_bar_text"><v-icon color='white' style="margin-right: 2%;">mdi-alert-circle-outline</v-icon>Error! Missing Selections</span>
              </v-col>
            </transition>
            <!--Filter Selectors-->
            <v-row class="ma-0 pa-0" style="height: 80%; width: 100%; overflow-y: scroll;">
              <v-col sm="12">
                  <span class="install_filter_prompt">Choose your dimension which most closely represents <b>Campaign</b></span>
                  <v-autocomplete class="install_filter_options" v-model="selected_campaign_dimension" :items="available_dimensions" item-text="name" item-value="value"></v-autocomplete>
              </v-col>
              <v-col sm="12">
                  <span class="install_filter_prompt">Choose your dimension which most closely represents <b>Channel</b></span>
                  <v-autocomplete class="install_filter_options" v-model="selected_channel_dimension" :items="available_dimensions" item-text="name" item-value="value"></v-autocomplete>
              </v-col>
              <v-col sm="12" v-for="(filter_name,key) in Object.keys(custom_selected_dimensions)" :key="key">
                  <span class="install_filter_prompt">Choose your dimension which most closely represents <b>{{filter_name}}</b></span>
                  <v-autocomplete class="install_filter_options" :items="available_dimensions" item-text="name" item-value="value" @change="select_custom_filter_dimension(filter_name,$event)"></v-autocomplete>
              </v-col>
            </v-row>
            <!--Divider-->
            <v-divider class="install_divider" style="margin-left: 10%; margin-right: 10%;"></v-divider>
            <!--Add buttons-->
            <v-row class="ma-0 pa-0" style="height: 20%; width: 100%;" align="center" justify="center">
              <v-col cols="auto">
                <v-btn class="install_action_button" fab @click="overlay = !overlay"><v-icon color="white">mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
            <!--Overlay add custom filter-->
            <v-overlay :absolute="true" :value=overlay>
              <v-card class="pa-4" style="position: relative">
                <transition name="fade">
                  <v-col v-if="custom_filter_error == true" class="ma-0 pa-0 error_bar" sm="12" style="height: 10%; width: 100%; position: absolute; top: 0; left: 0;">
                    <span class="error_bar_text"><v-icon color='white' style="margin-right: 2%;">mdi-alert-circle-outline</v-icon>Error!</span>
                  </v-col>
                </transition>
                <v-card-title class="install_custom_filter_title">Custom Filter</v-card-title>
                <v-card-text>
                  <v-text-field class="install_custom_filter_prompt" v-model="custom_filter_name" hint="Enter the name of your custom filter" persistent-hint></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-row class="pa-0 ma-0" style="height: 100%; width: 100%;" justify="space-around">
                    <v-col class="ma-0 pa-1" sm="4">
                      <v-btn style="height: 100%; width: 100%;" @click="overlay = !overlay"><v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                    <v-col class="ma-0 pa-1" sm="4">
                      <v-btn style="height: 100%; width: 100%;" @click="add_new_filter(custom_filter_name)"><v-icon>mdi-check-circle-outline</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-overlay>
          </v-card>
          <!--Loading Card-->
          <v-card class="install_box" style="width: 100%; height: 100%;" v-if="loading">
            <v-row style="width: 100%; height: 100%;" align="center" justify="center">
              <v-col cols="auto">
                <v-progress-circular indeterminate color="#0070ff" size="100"></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row style="height: 10%; width: 100%;" v-if="!loading">
        <v-spacer></v-spacer>
        <v-col sm="4">
          <v-btn class="install_actions_text install_action_button" style="width: 40%; margin-left: 30%; margin-right: 30%;" @click="previous_page">Prev</v-btn>
        </v-col>
        <v-col sm="4">
          <v-btn class="install_actions_text install_action_button" style="width: 40%; margin-left: 30%; margin-right: 30%;" @click="finished_install">Next</v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row style="height: 10%; width: 100%;">

      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
    // import SmartCurveTitleBar from "../SmartCurveTitleBar";
    import * as datoramaApi from "@/javascript/DatoramaApiConnect.js"
    export default {
        name: "marketing_filters",
        components: {
            // SmartCurveTitleBar
        },
        data(){
          return {
              available_dimensions: [{'name':'test'}],
              loading: true,
              selected_campaign_dimension: null,
              selected_channel_dimension: null,
              custom_selected_dimensions: {},
              custom_filter_name: "",
              error: false,
              custom_filter_error: false,
              overlay: false
          }
        },
        async mounted() {
          let dimensions = await this.get_dimension_options();
          this.available_dimensions = dimensions;
          await this.sleep(1000);
          this.loading = false;
        },
        methods: {
          get_dimension_options: async function() {
              let dimensions = await datoramaApi.getDimensions();
              let dimension_map = [];
              let dimension_name = "";
              let dimension_key = "";
              for(dimension_key of Object.keys(dimensions)){
                dimension_name = dimensions[dimension_key]['displayName'];
                dimension_map.push({'value': dimension_key, 'name': dimension_name});
              }
              return dimension_map;
          },
          finished_install: function(){
              let campaign_dimension = this.selected_campaign_dimension;
              let channel_dimension = this.selected_channel_dimension;
              let custom_dimensions = true;
              for(let key of Object.keys(this.custom_selected_dimensions)){
                  if(this.custom_selected_dimensions[key] == null){
                      custom_dimensions = false;
                  }
              }
              if(campaign_dimension != null && channel_dimension != null && custom_dimensions){
                let filters_map = {'Filters': {'Campaign': campaign_dimension, 'Channel': channel_dimension}};
                for(let key of Object.keys(this.custom_selected_dimensions)){
                    filters_map['Filters'][key] = this.custom_selected_dimensions[key];
                }
                this.$emit('install_page_finished',filters_map);
              }
              else{
                  this.error = true;
                  this.kill_errors(3000);
              }
          },
          add_new_filter: function(filter_name){
              if(Object.keys(this.custom_selected_dimensions).includes(filter_name) || filter_name == null || filter_name == ""){
                  this.custom_filter_error = true;
                  this.kill_errors(3000);
              }
              else {
                  this.custom_selected_dimensions[filter_name] = null;
                  this.overlay = false;
                  this.custom_filter_name = "";
              }
          },
          select_custom_filter_dimension(filter_name,filter_dimension){
              this.custom_selected_dimensions[filter_name] = filter_dimension;
          },
          kill_errors: async function(ms){
              await this.sleep(ms);
              this.error = false;
              this.custom_filter_error = false;
          },
          sleep: function(ms){
              return new Promise(resolve => setTimeout(resolve, ms));
          },
          previous_page: function(){
                this.$emit('install_previous_page');
          },
        }
    }
</script>

<style scoped>
</style>
