/*
The Graph Node object is used to represent a node in the filter graph, it encapsulates data on the value and category of the node
as well as the nodes that it is related to.
 */
export default class GraphNode {
    constructor(val,category,otherCategories){
        this._val = val;
        this._category = category;
        this._connections = {};
        for(let other of otherCategories){
            this._connections[other] = [];
        }
    }
    /*
    Getter function for the data
     */
    get val(){
        return this._val;
    }
    get category(){
        return this._category;
    }
    /*
    The containsConnection function is used to determine if a node with the specified category and value is linked to this node
    params:
    -------
    category: the category of the node being searched for
    value: the value of the node being search for
    -------
    return:
    -------
    boolean if it exists
    -------
     */
    containsConnection(category, value){
        for(let connection of this._connections[category]){
            if(connection.val == value){
                return true;
            }
        }
        return false;
    }
    /*
    The add connection function is used to link a node to this one
    params:
    -------
    category: the category of the node being added
    connecting_node: the node being linked
    -------
    return:
    -------
    -------
     */
    addConnection(category,connecting_node){
        let exists = this.containsConnection(category,connecting_node.val);
        let paralleled = connecting_node.containsConnection(this._category,this._val);
        if(!exists){
            this._connections[category].push(connecting_node);
        }
        if(!paralleled){
            connecting_node.addConnection(this._category,this);
        }
    }
    /*
    The getValsAtCategory function is used to get the values of linked nodes for a specific category
    params:
    -------
    category: the category of the desired nodes
    -------
    return:
    -------
    a list of values
    -------
     */
    getValsAtCategory(category){
        let nodes = this._connections[category];
        let vals = [];
        for(let node of nodes){
            if(node.val != null && node.val != 'null') {
                vals.push(node.val);
            }
        }
        return vals;
    }
}