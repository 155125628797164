import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import gcharts from './plugins/googleCharts';
// import * as DA from '@datorama/sdk';
// import requests from './javascript/Mocks/mocks'
import firebase from 'firebase';

// Vue.config.productionTip = false;
//
// DA.init();
// DA.app.onLoad().then(data => {
//     let filter_configuration = {};
//     if(data.mode == 'EXPORT'){
//         console.log('export mode');
//         console.log(data);
//     }
//     if(data.mode == 'DEMO') {
//         filter_configuration = {
//             'filter_map': {
//                 'Campaign': {
//                     'datorama_name': 'Campaign',
//                     'filter_options': ['Q1','Q3','Q4']
//                 }
//             },
//             'variable_map': {
//                 'dependent_variables': ['Clicks'],
//                 'independent_variables': ['Spend']
//             }
//         };
//         DA.setMock({requests: requests});
//     }
//     else {
//         filter_configuration = data.config;
//     }
//     new Vue({
//         router,
//         vuetify,
//         gcharts,
//         render: h => h(App, {props: {'filter_configuration': filter_configuration}})
//     }).$mount('#app')
// });
const firebaseConfig = {
    apiKey: "AIzaSyDrZnDtdgm6S63iRSymXDKHNNPnqCqHCEM",
    authDomain: "docamsmartcurvedemo.firebaseapp.com",
    projectId: "docamsmartcurvedemo",
    storageBucket: "docamsmartcurvedemo.appspot.com",
    messagingSenderId: "745645690897",
    appId: "1:745645690897:web:eed86fee47e8580108e1b0",
    measurementId: "G-N7HR934Q1X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const filter_configuration = {
    'filter_map': {
        'Advertiser': {
            'datorama_name': 'Advertiser',
            'datorama_system_name': 'Advertiser',
            'filter_options': ['BATL', 'CAA', 'Philip Morris International', 'TD Bank (Multicultural)', 'CREA', 'Velo', "Vuse", "Weiser Lock", "Weston Foods","IQOS","Takeda",'Healthy Web Inc. dba Fullscript']
        },
        'Channel': {
            'datorama_name': 'Channel',
            'datorama_system_name': 'Channel',
            'filter_options': ['Social', 'Discovery Ads', 'SEM', 'Social Display Image','Youtube','Display', 'Video','Native Video', 'Connected TV', 'Pinterest', 'Social Display Video']
        }
    },
    'variable_map': {
        'dependent_variables': [{'display':'Clicks','system':'Clicks'},{'display': 'CPA','system': 'CPA'},{'display': 'Impressions','system': 'Impressions'},{'display': 'CPM','system': 'CPM'},{'display': 'CTR','system': 'CTR'},{'display': 'CPC','system': 'CPC'},{'display': 'CPCV','system': 'CPCV'},{'display': 'VCR','system': 'VCR'},{'display': 'Conversions','system': 'Conversions'}],
        'independent_variables': [{'display': 'Spend','system': 'Spend'},{'display': 'CPM','system': 'CPM'},{'display': 'Conversions','system': 'Conversions'}]
    },
    'filter_tree': {
        'order': ['Advertiser', 'Channel'],
        'tree': {"null": {"below": [{"BATL": {'below': [{'Discovery Ads': {'below': []}},{'SEM': {'below': []}},{'Social': {'below': []}},{'Social Display Image': {'below': []}}]}}, {'CAA': {'below': [{'Social': {'below': []}},{'Youtube': {'below': []}}]}},{'CREA': {'below': [{'Connected TV': {'below': []}},{'Native Video': {'below': []}},{'Display': {'below': []}},{'Pinterest': {'below': []}},{'Video': {'below': []}},{'Social Display Video': {'below': []}}]}},{'Philip Morris International': {'below': [{'Display': {'below': []}},{'Video': {'below': []}}]}},{'TD Bank (Multicultural)': {'below': [{'Display': {'below': []}}]}},{'Velo': {'below': [{'Display': {'below': []}}]}},{'Vuse': {'below': [{'Display': {'below': []}}]}},{'Weiser Lock': {'below': [{'Display': {'below': []}},{'SEM': {'below': []}},{'Social': {'below': []}}]}},{'Weston Foods': {'below': [{'Pinterest': {'below': []}}]}}]}}
    }
};

Vue.config.productionTip = false;
new Vue({
    router,
    vuetify,
    gcharts,
    render: h => h(App, {props: {'filter_configuration': filter_configuration}})
}).$mount('#app');
