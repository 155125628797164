/*
The DataController function is used to manage a set of data objects.
Encapsulating data on a list of data objects as well as an integer representing the next int val to represent the
dataset and a currently selected dataset
 */
import Data from './data.js'
export default class DataController {
    constructor(){
        this._dataset = {};
        this._curDataName = null;
        this._nextName = 0;
    }
    /*
    Add a new empty data object with name __
     */
    add_data_object(data_name){
        let data_obj = new Data();
        data_obj.dataName = data_name;
        this._dataset[data_name] = data_obj;
    }
    /*
    Update the data object associated with a name
     */
    update_data_object(data_name, data){
        this._dataset[data_name] = data;
    }
    /*
    The get_next_name function is used to get a new default name for a dataset,
    each name is given an incrementing integer in its name
     */
    get_next_name(){
        this._nextName += 1;
        return "DataSet " + this._nextName;
    }
    /*
    Getter and setter for the name of the currently selected data object.
     */
    set curDataName(name){
        this._curDataName = name;
    }
    get curDataName(){
        return this._curDataName;
    }
    /*
    Get the currently selected data object
     */
    get curData(){
        if(Object.keys(this._dataset).includes(this._curDataName) && this._curDataName != null) {
            return this._dataset[this._curDataName];
        }
        else {
            return new Data();
        }
    }
    /*
    Get a list of data object names
     */
    get_dataset_names(){
        return Object.keys(this._dataset);
    }
    /*
    Get the number of data objects the data controller has
     */
    get_num_data_objs(){
        return Object.keys(this._dataset).length;
    }
    /*
    Retrieve a data object in the set by its name
     */
    get_data_obj_by_name(name){
        return this._dataset[name];
    }
    /*
    Delete a data object in the set by its name
     */
    delete_data_obj_by_name(name){
        if(this._curDataName == name){
            this._curDataName = "";
        }
        delete this._dataset[name];
    }
    /*
    The update_data_obj_name function is used is used to update the name of a data object
    params:
    -------
    oldName: The current name of the data object
    newName: The new desired name
    -------
    return:
    -------
    true/false, weather or not the update was possible
    -------
     */
    update_data_obj_name(oldName,newName){
        if(this.get_dataset_names().includes(oldName) && !this.get_dataset_names().includes(newName)) {
            let data_obj = this.get_data_obj_by_name(oldName);
            data_obj.dataName = newName;
            this.update_data_object(newName,data_obj);
            this.delete_data_obj_by_name(oldName);
            if(this._curDataName == ""){
                this._curDataName = newName;
            }
            return true;
        }
        return false;
    }
    /*
    Get Max value functions.
    This collection of functions are used to get maximum values across all data objects in the dataset
     */
    getAllMaxData(){
        let maxChampX = 0;
        let maxChampY = 0;
        for(let dataName of this.get_dataset_names()){
            let tmpXmax = this._dataset[dataName].getMax('data','x');
            let tmpYmax = this._dataset[dataName].getMax('data','y');
            if(tmpXmax > maxChampX){
                maxChampX = tmpXmax;
            }
            if(tmpYmax > maxChampY){
                maxChampY = tmpYmax;
            }
        }
        return [maxChampX, maxChampY];
    }
    getAllMaxNoSmartcurve(){
        let maxChampX = 0;
        let maxChampY = 0;
        for(let dataName of this.get_dataset_names()){
            let tmpXmax = this._dataset[dataName].getMax('no_smartcurve','x');
            let tmpYmax = this._dataset[dataName].getMax('no_smartcurve','y');
            if(tmpXmax > maxChampX){
                maxChampX = tmpXmax;
            }
            if(tmpYmax > maxChampY){
                maxChampY = tmpYmax;
            }
        }
        return [maxChampX, maxChampY];
    }
    getAllMaxNoOutliers(){
        let maxChampX = 0;
        let maxChampY = 0;
        for(let dataName of this.get_dataset_names()){
            let tmpXmax = this._dataset[dataName].getMax('no_outliers','x');
            let tmpYmax = this._dataset[dataName].getMax('no_outliers','y');
            if(tmpXmax > maxChampX){
                maxChampX = tmpXmax;
            }
            if(tmpYmax > maxChampY){
                maxChampY = tmpYmax;
            }
        }
        return [maxChampX, maxChampY];
    }
    getAllMax(){
        let maxChampX = 0;
        let maxChampY = 0;
        for(let dataName of this.get_dataset_names()){
            let tmpXmax = this._dataset[dataName].getMax('all','x');
            let tmpYmax = this._dataset[dataName].getMax('all','y');
            if(tmpXmax > maxChampX){
                maxChampX = tmpXmax;
            }
            if(tmpYmax > maxChampY){
                maxChampY = tmpYmax;
            }
        }
        return [maxChampX, maxChampY];
    }
}