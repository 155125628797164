<template>
  <div>
    <v-app-bar color="#0070ff" style="height: 8vh" class="ma-0 pa-0" flat>
      <v-container style="height: 8vh; position: absolute; top: 0; left:0; right:0;" class="pa-0">
        <v-img :src="require('../assets/smartcurve.svg')" contain max-height="50%" style="position: absolute; top:25%; bottom: 25%;"></v-img>
      </v-container>
    </v-app-bar>
    <v-container class="pa-0" style="height: 90vh;">
      <keep-alive>
        <component :is="current_install_page" v-on:install_page_finished="move_to_next_setup_page" v-on:install_previous_page="move_to_previous_setup_page"></component>
      </keep-alive>
    </v-container>
  </div>
</template>

<script>
    import filter_setup_selector from '@/components/install/filter_setup_selector'
    import marketing_filters from '@/components/install/marketing_filters'
    import custom_filters from '@/components/install/custom_filters'
    import variables from '@/components/install/variables'
    import setupFinished from '@/components/install/setupFinished'
    import DatoramaQuery from '@/javascript/DatoramaQuery.js'
    import FilterTree from '@/javascript/FilterTree.js'
    import Graph from '@/javascript/Graph.js';
    import FilterController from '@/javascript/FilterController'
    import * as DA from '@datorama/sdk';
    export default {
        name: "Install",
        data(){
          return {
            FILTER_INSTALL_SELECTOR: filter_setup_selector,
            FILTER_MARKETING_INSTALL: marketing_filters,
            FILTER_CUSTOM_INSTALL: custom_filters,
            VARIABLE_INSTALL: variables,
            INSTALL_FINISHED: setupFinished,
            current_install_page: null,
            chosen_filter_page: null,
            install_configuration: {},
            filter_tree: null,
            graph: null
          }
        },
        mounted(){
          this.current_install_page = this.FILTER_INSTALL_SELECTOR
        },
        methods: {
            move_to_next_setup_page(value){
                if(this.current_install_page == this.FILTER_INSTALL_SELECTOR){
                    if(value == 'Marketing'){
                        this.current_install_page = this.FILTER_MARKETING_INSTALL;
                        this.chosen_filter_page = 'Marketing';
                    }
                    else if(value == 'Custom'){
                        this.current_install_page = this.FILTER_CUSTOM_INSTALL;
                        this.chosen_filter_page = 'Custom';
                    }
                }
                else if(this.current_install_page == this.FILTER_MARKETING_INSTALL || this.current_install_page == this.FILTER_CUSTOM_INSTALL){
                    if(typeof value == 'object' && Object.keys(value).length == 1 && Object.keys(value)[0] == 'Filters'){
                      this.install_configuration['Filters'] = value['Filters']
                    }
                    this.current_install_page = this.VARIABLE_INSTALL;
                }
                else if(this.current_install_page == this.VARIABLE_INSTALL){
                    if(typeof value == 'object' && Object.keys(value).length == 1 && Object.keys(value)[0] == 'Variables'){
                      this.install_configuration['Variables'] = value['Variables']
                    }
                    this.setupFilterController();
                    this.current_install_page = this.INSTALL_FINISHED;
                }
            },
            move_to_previous_setup_page(){
                if(this.current_install_page == this.FILTER_MARKETING_INSTALL || this.current_install_page == this.FILTER_CUSTOM_INSTALL){
                    this.current_install_page = this.FILTER_INSTALL_SELECTOR;
                }
                else if(this.current_install_page == this.VARIABLE_INSTALL){
                    if(this.chosen_filter_page == 'Custom') {
                        this.current_install_page = this.FILTER_CUSTOM_INSTALL;
                    }
                    else if(this.chosen_filter_page == 'Marketing'){
                        this.current_install_page = this.FILTER_MARKETING_INSTALL;
                    }
                }
            },
            async setupFilterController(){
                console.log('here');
                let query = new DatoramaQuery();
                let dimensions = [];
                for(let key of Object.keys(this.install_configuration['Filters'])){
                    query.add_dimension_to_query(this.install_configuration['Filters'][key]);
                    dimensions.push(this.install_configuration['Filters'][key]);
                }
                let today_str = '2021-01-24';
                query.endDate= today_str;
                let response = await query.run_query(dimensions,true);
                console.log(response);
                let filterTree = new FilterTree(dimensions);
                filterTree.buildTreeFrom2DvalArray(response);
                this.filter_tree = filterTree;
                let graph = new Graph(dimensions);
                graph.buildGraphFrom2DvalArray(response);
                this.graph = graph;
                let filter_controller = new FilterController();
                await filter_controller.setup_new_controller(this.install_configuration,this.filter_tree);
                let install_configuration = filter_controller.get_json_representation();
                console.log(install_configuration);
                DA.app.install({config: install_configuration});
            }
        }
    }
</script>

<style scoped>

</style>
