<template>
  <div id="home" class="home">
    <v-app-bar color="#0070ff" style="height: 8vh" class="ma-0 pa-0" flat>
        <v-container style="height: 8vh; position: absolute; top: 0; left:0; right:0;" class="pa-0">
            <v-img :src="require('../assets/smartcurve.svg')" contain max-height="50%" style="position: absolute; top:25%; bottom: 25%;"></v-img>
        </v-container>
        <v-icon style="position: absolute; top: 2vh; right: 4vh; z-index: 2; height: 4vh; width: 4vh" color="white" @click="logout">mdi-exit-run</v-icon>
    </v-app-bar>
    <v-container class="pa-0" style="height: 92vh;">
        <v-row wrap style="height: 100%; width: 100%">
          <v-col sm="12" class="ma-0 pa-0" style="height: calc(92vh * 0.555); margin-top: calc(92vh * 0.03) !important; margin-bottom: calc(92vh * 0.015) !important;">
            <smart-curve-graph2 :graphData="data" :loading="loading"></smart-curve-graph2>
          </v-col>
          <v-col sm="12" class="ma-0 pa-0" style="height: calc(92vh * 0.355); margin-top: calc(92vh * 0.015) !important; margin-bottom: calc(92vh * 0.03) !important;">
            <Dashboard :filterController="filter_controller" :apiToken="apiToken" v-on:emitPackagedData="processPackagedData"></Dashboard>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import Data from '@/javascript/data.js'
import FilterController from '@/javascript/FilterController'
import SmartCurveGraph2 from '@/components/SmartCurveGraph.vue'
import Dashboard from '@/components/Dashboard.vue'
import firebase from 'firebase'
// import sdk from '@datorama/sdk'
export default {
    name: 'Run',
    components: {
        SmartCurveGraph2,
        Dashboard
    },
    props: [
      'filter_configuration',
      'apiToken'
    ],
    data (){
      return{
          data: new Data(),
          loading: false,
          filter_controller: null
      }
    },
    async mounted(){
        this.filter_controller = new FilterController();
        await this.filter_controller.setup_from_configuration(this.filter_configuration);
        console.log(this.apiToken)
    },
    methods: {
        processPackagedData: function(data){
            this.loading = true;
            this.data = data;
            this.sleeper(250);
        },
        set_loading: function(state){
            this.loading = state;
        },
        sleeper: async function(ms){
            await this.sleep(ms);
            this.loading = false;
        },
        sleep: function(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        logout: function(){
            firebase.auth().signOut().then( () => {
                this.$router.replace('Login')
            })
        }
    }
}
</script>
