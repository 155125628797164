import GraphNode from "./GraphNode";
export default class Graph {
    constructor(categories){
        this._categories = categories;
        this._graphNodesAtCategory = {};
        for(let cat of categories){
            this._graphNodesAtCategory[cat] = [];
        }
    }
    //The val array is a 2D array where the val for each spot in the order is along the x
    //Along the y is the various combinations of vals in the order
    buildGraphFrom2DvalArray(valArray){
        for(let row of valArray) {
            let i = 0;
            for(let val of row){
                let upperCat = this._categories[i];
                let node = null;
                if(this.nodeExistsAtCat(upperCat,val)){
                    node = this.getNodeAtCat(upperCat,val);
                }
                else{
                    node = new GraphNode(val,upperCat,this._categories);
                    this._graphNodesAtCategory[upperCat].push(node);
                }
                let j = 0;
                for(let v of row){
                    if(i == j){
                        j += 1;
                        continue;
                    }
                    let lowerCat = this._categories[j];
                    let lower_node = null;
                    if(this.nodeExistsAtCat(lowerCat,v)){
                        lower_node = this.getNodeAtCat(lowerCat,v);
                    }
                    else{
                        lower_node = new GraphNode(v,lowerCat,this._categories);
                        this._graphNodesAtCategory[lowerCat].push(lower_node);
                    }
                    node.addConnection(lowerCat,lower_node);
                    j += 1;
                }
                i += 1;
            }
        }
    }
    /*
    The nodeExistsAtCat function is used to check if the graph contains a node with category and value
    params:
    -------
    cat: Category of searched node
    val: values of searched node
    -------
    return:
    -------
    boolean if exists
    -------
     */
    nodeExistsAtCat(cat,val){
        for(let node of this._graphNodesAtCategory[cat]){
            if(node.val == val){
                return true;
            }
        }
        return false;
    }
    /*
    The getNodeAtCat function is used to retrieve a node from the graph which contains a category and value
    params:
    -------
    cat: Category of searched node
    val: values of searched node
    -------
    return:
    -------
    the node or false if doesn't exist
    -------
     */
    getNodeAtCat(cat,val){
        for(let node of this._graphNodesAtCategory[cat]){
            if(node.val == val){
                return node;
            }
        }
        return false;
    }
    /*

     */
    get_values_over(current_cat,current_val,next_cat){
        let values = [];
        for(let val of current_val) {
            for (let node of this._graphNodesAtCategory[current_cat]) {
                if (node.val == val){
                    values = values.concat(node.getValsAtCategory(next_cat));
                }
            }
        }
        return values.filter((v, i, a) => a.indexOf(v) === i);
    }
    /*

     */
    get_values_skip(current_cat,current_val,next_cat,next_val,skip_cat){
        let skip_values = [];
        let values = [];
        for(let val of current_val) {
            for (let node of this._graphNodesAtCategory[current_cat]) {
                if (node.val == val){
                    values = values.concat(node.getValsAtCategory(skip_cat));
                }
            }
        }
        for(let val of next_val) {
            for (let node of this._graphNodesAtCategory[next_cat]) {
                if (node.val == val){
                    let next_values = node.getValsAtCategory(skip_cat);
                    for(let v of next_values){
                        if(values.includes(v)){
                            skip_values.push(v);
                        }
                    }
                }
            }
        }
        return skip_values.filter((v, i, a) => a.indexOf(v) === i);
    }
}