<template>
  <v-container style="height: 100%; width: 100%;">
    <v-layout style="height: 100%; width: 100%;" wrap="True">
      <v-row style="height: 85%; width: 100%;" align="center" justify="center">
        <v-col cols="auto">
          <span class="install_finished">Setup Complete</span>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
    export default {
        name: "setupFinished"
    }
</script>

<style scoped>

</style>
