<template>
  <v-app>
    <router-view :filter_configuration="filter_configuration" :apiToken="apiToken" v-on:newApiToken="setApiToken"></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  props: [
      'filter_configuration'
  ],
  components: {
  },
  mounted(){
  },
  data (){
      return{
          apiToken: ""
      }
  },
  methods: {
      setApiToken: function(token){
        this.apiToken = token;
      }
  }
};
</script>
<style>
  @import 'css/system_responses.css';
  @import 'css/DatoramaDesign/install.css';
  @import 'css/DatoramaDesign/dashboard.css';
  @import 'css/DatoramaDesign/filters.css';
  @import 'css/DatoramaDesign/graphs.css';
  @import 'css/DatoramaDesign/Run.css';
  @font-face {
    font-family: "Sofia Pro Bold";
    src: local("Sofia Pro Bold"),
    url(./assets/font/Sofia_Pro_Bold.otf) format("truetype");
  }
  @font-face {
    font-family: "Sofia Pro Light";
    src: local("Sofia Pro Light"),
    url(./assets/font/Sofia_Pro_Light.otf) format("truetype");
  }
  @font-face {
    font-family: "Sofia Pro Regular";
    src: local("Sofia Pro Regular"),
    url(./assets/font/Sofia_Pro_Regular.otf) format("truetype");
  }
  @font-face {
    font-family: "Sofia Pro Black";
    src: local("Sofia Pro Black"),
    url(./assets/font/Sofia_Pro_Black.otf) format("truetype");
  }
</style>
