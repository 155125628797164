import get_data_from_filters from './mockData';
function remove_outliers(filters){
    let campaign_filters = ['Q1','Q3','Q4'];
    if(Object.keys(filters).includes('Campaign') && filters['Campaign'].length > 0 && filters['Campaign'] != null){
        campaign_filters = filters['Campaign'];
    }
    return get_data_from_filters('Spend','Clicks',campaign_filters,'outliers');
}
function get_smartcurve(filters){
    let campaign_filters = ['Q1','Q3','Q4'];
    if(Object.keys(filters).includes('Campaign') && filters['Campaign'].length > 0 && filters['Campaign'] != null){
        campaign_filters = filters['Campaign'];
    }
    return get_data_from_filters('Spend','Clicks',campaign_filters,'smartcurve');
}

export {remove_outliers, get_smartcurve}
