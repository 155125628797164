<!--
Created on Sat Feb 1 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the SmartCurve Graph vue component of the SmartCurve Application.
This component is used to display the users various datasets.
-->
<template>
    <div style="height: 100%; width: 100%;">
        <v-card class="chart-wrapper pa-3" v-if="graph_loading">
            <v-row class="pa-0 ma-0" justify="center" align="center" style="height: 100%; width: 100%;">
                <v-col cols="auto">
                    <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="!graph_loading" class="pa-0 chart-wrapper" style="width: 100%; height: 100%; position: relative;">
            <div id="box_selector" style="width: 80%; height: 70%; position: absolute; top:10%; left: 12.5%; z-index: 2" v-if="box_selector">
            </div>
            <transition name="flip">
                <GChart v-if="flipped"
                        style="width: 100%; height: 100%; position: absolute; z-index: 1"
                        type="ComboChart"
                        :data="rawData"
                        :options="chartOptions"
                        ref="smartCurveRawGraph"
                        />
                <graph-legend v-if="!flipped" style="position: absolute" :dataController="graphData" :loading="loading" :multi="!view_individual"></graph-legend>
            </transition>
            <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{on, attrs}">
                    <v-icon v-show="flipped" style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%;" color="primary" @click="flipped = !flipped" v-on="on" v-bind="attrs">mdi-view-list-outline</v-icon>
                </template>
                <span>View Legend</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{on, attrs}">
                    <v-icon v-show="!flipped && view_individual" style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%;" color="primary" @click="flipped = !flipped" v-on="on" v-bind="attrs">mdi-chart-line</v-icon>
                </template>
                <span>View Graph</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{on, attrs}">
                    <v-icon v-show="!flipped && !view_individual" style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%;" color="primary" @click="flipped = !flipped" v-on="on" v-bind="attrs">mdi-chart-multiple</v-icon>
                </template>
                <span>View Graph</span>
            </v-tooltip>
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{on: onMenu}">
                    <v-tooltip bottom open-delay="1000">
                        <template v-slot:activator="{on: onTooltip}">
                            <v-icon style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%; top: 10%;" v-on="{...onMenu,...onTooltip}" color="primary">mdi-cog-outline</v-icon>
                        </template>
                        <span>Graph Settings</span>
                    </v-tooltip>
                </template>
                <v-card class="pa-3" style="z-index: 4">
                    <v-switch class="ma-0 pa-0" v-model="view_individual" label="View Curves Individually" @change="compute_raw_data(); set_min_max()" color="primary"></v-switch>
                    <v-switch class="ma-0 pa-0" v-model="view_outliers" label="View Outliers" @change="compute_raw_data(); set_min_max();" color="primary"></v-switch>
                    <v-switch class="ma-0 pa-0" v-model="view_smartcurve" label="View SmartCurve" @change="compute_raw_data(); set_min_max();" color="primary"></v-switch>
                    <span>X axis</span>
                    <v-range-slider v-model="x_axis" :min="x_axis_min" :max="x_axis_max" thumb-label @change="user_set_x_axis" color="primary"></v-range-slider>
                    <span>Y axis</span>
                    <v-range-slider v-model="y_axis" :min="y_axis_min" :max="y_axis_max" thumb-label @change="user_set_y_axis" color="primary"></v-range-slider>
                </v-card>
            </v-menu>
            <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{on: onTooltip}">
                    <v-icon style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%; top: 20%" @click="box_selector = !box_selector; box_selector_click = 0; graph_selected();" :style="[box_selector ? {color: '#0070ff'} : {color: 'rgba(0,0,0,0.54)'}]" v-on="onTooltip">mdi-magnify-plus-cursor</v-icon>
                </template>
                <span>Box Zoom</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="1000">
                <template v-slot:activator="{on: onTooltip}">
                    <v-icon style="width: 8.5%; height: 8.5%; position: absolute; z-index: 4; right: 0%; top: 30%" @click="pop_prev_selection" v-on="onTooltip" :style="[previous_selections.length > 0 ? {color: '#0070ff'} : {color: 'rgba(0,0,0,0.54)'}]">mdi-magnify-minus-cursor</v-icon>
                </template>
                <span>Undo Box Zoom</span>
            </v-tooltip>
        </v-card>
    </div>
</template>

<script>
    import GraphLegend from './GraphLegend';
    export default {
        name: "SmartCurveGraph",
        props: [
          'graphData',
          'loading'
        ],
        components: {
           GraphLegend
        },
        data () {
            return {
                // Array will be automatically processed with visualization.arrayToDataTable function
                dataHeader: [
                    {"id":"","label":"x","type":"number"},
                    {"id":"","label":"y","type":"number"},
                    {"id":"","label":"o","type":"number"},
                    {"id":"","label":"r","type":"number"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"id":"","label":"p","type":"number"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"id":"","label":"error", "type":"number", "role": "interval"}],
                rawData: {"cols": [{"id":"","label":"x","type":"number"}, {"id":"","label":"y","type":"number"}], "rows": [], "p": null},
                maxY: 10,
                chartOptions: {
                    title: '',
                    titleTextStyle: {fontName: 'Poppins', fontSize: 16, bold: true},
                    colors: ['#0070ff','#ff63a7','#0070ff','#ff63a7','#EE6352','#59CD90','#EE6352','#59CD90','FAC05E','A53860','FAC05E','A53860'],
                    vAxes: { 0:{title: '', textStyle:{fontName: 'Amiko', fontSize: 12, italic: true}}},
                    vAxis: {viewWindowMode: 'explicit', viewWindow: {min: 0, max:1}, baselineColor: '#000000', textStyle:{fontName: 'Amiko', fontSize: 10}, gridlines:{count: 0}, minorGridlines:{count: 0}},
                    hAxis: {viewWindowMode: 'explicit', viewWindow: {min: 0, max:1}, title: '', format: '', slantedText: true, slantedTextAngle: 30, baselineColor: '#000000', textStyle:{fontName: 'Amiko', fontSize: 10}, titleTextStyle:{fontName: 'Poppins', fontSize: 12, italic: true}, gridlines:{count:0}, minorGridlines:{count: 0}},
                    legend: { position: 'none'},
                    chartArea: {left: '12.5%', top: '10%', width: '80%', height: '70%'},
                    pointSize: 5,
                    seriesType: 'scatter',
                    series: {
                        2: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        },
                        3: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        },
                        6: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        },
                        7: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        },
                        10: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        },
                        11: {
                            curveType: 'function',
                            lineWidth: 2,
                            pointSize: 0,
                            intervals: {style: 'area'}
                        }
                    },
                    tooltip: {isHtml: true}
                },
                view_outliers: true,
                view_smartcurve: true,
                view_individual: true,
                x_axis: [0,0],
                x_axis_min: 0,
                x_axis_max: 1,
                y_axis: [0,0],
                y_axis_min: 0,
                y_axis_max: 1,
                box_selector: false,
                box_selector_click: 0,
                previous_selections: [],
                flipped: true,
                graph_loading: true
            }
        },
        watch: {
            loading: {
                handler: async function(){
                    if(this.loading == false){
                        this.graph_loading = true;
                        this.compute_raw_data();
                        this.set_min_max();
                        await this.sleep(500);
                        this.graph_loading = false;
                    }
                    else{
                        this.graph_loading = true;
                    }
                }
            },
            view_individual: {
                handler: function(){
                    this.$emit('individual_curves',this.view_individual);
                }
            }
        },
        methods: {
            /*
            The compute_raw_data function is used to determine which raw_data_compute function to use
            based on weather or not the option to view multiple curves is selected.
            */
            compute_raw_data(){
                if(this.view_individual){
                    this.compute_raw_data_individual();
                }
                else{
                    this.compute_raw_data_multiple();
                }
            },
            /*
            The compute_raw_data_individual function is used to build the dataframe for the GChart when the option to view a
            single curve is selected
            */
            compute_raw_data_individual(){
                this.dataHeader = [{"id":"","label":"x","type":"number"},
                    {"id":"","label":"y","type":"number"},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"o","type":"number"},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"r","type":"number"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"p","type":"number"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"id":"","label":"error", "type":"number", "role": "interval"},
                    {"type": "string","role": "tooltip","p":{"html":true}}];
                var drow = [];
                let data_obj = this.graphData.curData;
                var data = data_obj.xyData;
                let clustered = false;
                if(data.length > 0 && data[0].length > 3){
                    this.dataHeader.splice(3,0,{"type": "string","role": "style"});
                    clustered = true;
                }
                let dataName = data_obj.dataName;
                let dpoint = [];
                let cluster_shapes = ['{ size: 7; shape-type: star;}','{ size: 7; shape-type: triangle;}','{ size: 7; shape-type: square;}','{ size: 7; shape-type: diamond;}','{ size: 7; shape-type: polygon;}'];
                let cluster_map = {};
                for(var row of data){
                    if(!clustered) {
                        dpoint = [{"v": row[0]}, {"v": row[1]}, {"v": this.data_tooltip(row[0], row[1], row[2], dataName)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                    }
                    else{
                        let shape = "point";
                        if(Object.keys(cluster_map).includes(row[3])){
                            shape += cluster_map[row[3]];
                        }
                        else{
                            cluster_map[row[3]] = cluster_shapes[Object.keys(cluster_map).length];
                            shape += cluster_shapes[Object.keys(cluster_map).length];
                        }
                        dpoint = [{"v": row[0]}, {"v": row[1]}, {"v": this.data_tooltip(row[0], row[1], row[2], dataName, row[3])}, {"v": shape}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                    }
                    drow = drow.concat({"c":dpoint});
                }
                if(this.view_outliers) {
                    let outliers = data_obj.xyDataOutliers;
                    for (let row of outliers) {
                        if(!clustered) {
                            dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": this.data_tooltip(row[0], row[1], '', dataName)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                        }
                        else{
                            dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": this.data_tooltip(row[0], row[1], '', dataName)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                        }
                        drow = drow.concat({"c": dpoint});
                    }
                }
                if(this.view_smartcurve) {
                    data = data_obj.regressionLine;
                    let error = data_obj.error;
                    if (data.length != 0) {
                        for (let row of data) {
                            if(!clustered) {
                                dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error}, {"v": this.curve_tooltip(row[0], row[1], error)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                            }
                            else{
                                dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error}, {"v": this.curve_tooltip(row[0], row[1], error)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}];
                            }
                            drow = drow.concat({"c": dpoint});
                        }
                    }
                    data = data_obj.predictionLine;
                    if (data.length != 0) {
                        for (let row of data) {
                            if(!clustered) {
                                dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error}, {"v": this.curve_tooltip(row[0], row[1], error)}];
                            }
                            else{
                                dpoint = [{"v": row[0]}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error}, {"v": this.curve_tooltip(row[0], row[1], error)}];
                            }
                            drow = drow.concat({"c": dpoint});
                        }
                    }
                }
                if(drow.length == 0){
                    drow.concat({"c": [{"v": 0},{"v": 0},{"v":this.data_tooltip(0,0,'None')},{"v":null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]});
                }
                this.chartOptions.vAxes["0"].title = data_obj.dependentVar;
                this.chartOptions.hAxis.title = data_obj.independentVar;
                this.rawData = {"cols":this.dataHeader, "rows": drow, p: "null"};
            },
            /*
            The compute_raw_data_multiple function is used to build the dataframe for the GChart when the option to view multiple
            curve is selected.
            */
            compute_raw_data_multiple(){
                let data_header_tmp =   [{"id":"","label":"y","type":"number","p":{"role": "data"}},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"o","type":"number"},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"r","type":"number"},
                    {"id":"","label":"error", "type":"number", "p":{"role": "interval"}},
                    {"id":"","label":"error", "type":"number", "p":{"role": "interval"}},
                    {"type": "string","role": "tooltip","p":{"html":true}},
                    {"id":"","label":"p","type":"number"},
                    {"id":"","label":"error", "type":"number", "p":{"role": "interval"}},
                    {"id":"","label":"error", "type":"number", "p":{"role": "interval"}},
                    {"type": "string","role": "tooltip","p":{"html":true}}];
                this.dataHeader = [{"id":"","label":"x","type":"number","p":{"role": "domain"}}];
                let num_datasets = this.graphData.get_num_data_objs();
                if(num_datasets == 0){
                    num_datasets = 1;
                }
                let d = 1;
                let data_obj_names = this.graphData.get_dataset_names();
                while(d <= num_datasets){
                    this.dataHeader = this.dataHeader.concat(data_header_tmp);
                    d += 1;
                }
                let drow = [];
                let data = null;
                let data_name = "";
                for(d = 0; d < data_obj_names.length; d++) {
                    data = this.graphData.get_data_obj_by_name(data_obj_names[d]).xyData;
                    data_name = this.graphData.get_data_obj_by_name(data_obj_names[d]).dataName;
                    if (data.length == 0) {
                        data = [[0, 0]];
                    }
                    for (var row of data) {
                        let dpoint = [{"v": row[0]}];
                        for(let i = 0; i < d; i++){
                            dpoint = dpoint.concat([{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                        }
                        dpoint = dpoint.concat([{"v": row[1]},{"v":this.data_tooltip(row[0],row[1],row[2],data_name)},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                        for(let j = (d+1); j < data_obj_names.length; j ++){
                            dpoint = dpoint.concat([{"v": null}, {"v": null},{"v": null}, {"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                        }
                        drow = drow.concat({"c": dpoint});
                    }
                }
                if(this.view_outliers) {
                    for(d = 0; d < data_obj_names.length; d++) {
                        data = this.graphData.get_data_obj_by_name(data_obj_names[d]).xyDataOutliers;
                        data_name = this.graphData.get_data_obj_by_name(data_obj_names[d]).dataName;
                        for (let row of data) {
                            let dpoint = [{"v": row[0]}];
                            for(let i = 0; i < d; i++){
                                dpoint = dpoint.concat([{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                            }
                            dpoint = dpoint.concat([{"v": null},{"v":null}, {"v": row[1]}, {"v": this.data_tooltip(row[0],row[1],'', data_name)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                            for(let j = (d+1); j < data_obj_names.length; j ++){
                                dpoint = dpoint.concat([{"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                            }
                            drow = drow.concat({"c": dpoint});
                        }
                    }
                }
                if(this.view_smartcurve) {
                    for(d = 0; d < data_obj_names.length; d++) {
                        data = this.graphData.get_data_obj_by_name(data_obj_names[d]).regressionLine;
                        let error = this.graphData.get_data_obj_by_name(data_obj_names[d]).error;
                        if (data.length != 0) {
                            for (let row of data) {
                                let dpoint = [{"v": row[0]}];
                                for(let i = 0; i < d; i++){
                                    dpoint = dpoint.concat([{"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                                }
                                dpoint = dpoint.concat([{"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error}, {"v": this.curve_tooltip(row[0],row[1],error)}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]);
                                for(let j = (d+1); j < data_obj_names.length; j ++){
                                    dpoint = dpoint.concat([{"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": null}]);
                                }
                                drow = drow.concat({"c": dpoint});
                            }
                        }
                        data = this.graphData.get_data_obj_by_name(data_obj_names[d]).predictionLine;
                        if (data.length != 0) {
                            for (let row of data) {
                                let dpoint = [{"v": row[0]}];
                                for(let i = 0; i < d; i++){
                                    dpoint = dpoint.concat([{"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": null}]);
                                }
                                dpoint = dpoint.concat([{"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": row[1]}, {"v": row[1] + error}, {"v": row[1] - error},{"v": this.curve_tooltip(row[0],row[1],error)}]);
                                for(let j = (d+1); j < data_obj_names.length; j ++){
                                    dpoint = dpoint.concat([{"v": null},{"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null},{"v": null}, {"v": null}]);
                                }
                                drow = drow.concat({"c": dpoint});
                            }
                        }
                    }
                }
                if(drow.length == 0){
                    drow.concat({"c": [{"v": 0},{"v": 0},{"v":this.data_tooltip(0,0,'None')}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}, {"v": null}]});
                }
                let ind = [];
                let dep = [];
                for(d = 0; d < data_obj_names.length; d++) {
                    data = this.graphData.get_data_obj_by_name(data_obj_names[d]);
                    ind.push(data.independentVar);
                    dep.push(data.dependentVar);
                }
                ind = [...new Set(ind)];
                dep = [...new Set(dep)];
                let ind_var = "";
                let dep_var = "";
                for(let i = 0; i < ind.length; i ++){
                    if(i == (ind.length - 1)){
                        ind_var += ind[i];
                    }
                    else{
                        ind_var += ind[i] + ' / ';
                    }
                }
                for(let i = 0; i < dep.length; i ++){
                    if(i == (dep.length - 1)){
                        dep_var += dep[i];
                    }
                    else{
                        dep_var += dep[i] + ' / ';
                    }
                }
                this.chartOptions.vAxes["0"].title = dep_var;
                this.chartOptions.hAxis.title = ind_var;
                this.rawData = {"cols":this.dataHeader, "rows": drow, p: "null"};
            },
            /*
            The set_min_max function is used to determine the graph axis min and max's,
            based on the settings selected
            */
            set_min_max(){
                let x_min = 0;
                let x_max = 0;
                let y_min = 0;
                let y_max = 0;
                if(this.view_individual) {
                    let data_obj = this.graphData.curData;
                    let data_part = '';
                    if (this.view_outliers && this.view_smartcurve) {
                        data_part = 'all';
                    }
                    else if(this.view_smartcurve){
                        data_part = 'no_outliers';
                    }
                    else if(this.view_outliers){
                        data_part = 'no_smartcurve';
                    }
                    else {
                        data_part = 'data'
                    }
                    x_max = data_obj.getMax(data_part,'x') * 1.2;
                    y_max = data_obj.getMax(data_part,'y') * 1.2;
                }
                else {
                    let maxs = [1,1];
                    if (this.view_outliers && this.view_smartcurve) {
                        maxs = this.graphData.getAllMax();
                    }
                    else if(this.view_smartcurve){
                        maxs = this.graphData.getAllMaxNoOutliers();
                    }
                    else if(this.view_outliers){
                        maxs = this.graphData.getAllMaxNoSmartcurve();
                    }
                    else {
                        maxs = this.graphData.getAllMaxData();
                    }
                    x_max = maxs[0] * 1.2;
                    y_max = maxs[1] * 1.2;
                }
                this.x_axis = [x_min,x_max];
                this.y_axis = [y_min,y_max];
                this.x_axis_min = x_min;
                this.x_axis_max = x_max;
                this.y_axis_min = y_min;
                this.y_axis_max = y_max;
                this.chartOptions.vAxis.viewWindow.min = y_min;
                this.chartOptions.vAxis.viewWindow.max = y_max;
                this.chartOptions.hAxis.viewWindow.min = x_min;
                this.chartOptions.hAxis.viewWindow.max = x_max;
                this.previous_selections = [];
            },
            /*
            The user_set_x_axis function is used to set the min and max of the graph x axis based on user inputted variables
            */
            user_set_x_axis(){
                let x_min = this.x_axis[0];
                let x_max = this.x_axis[1];
                this.chartOptions.hAxis.viewWindow.min = x_min;
                this.chartOptions.hAxis.viewWindow.max = x_max;
            },
            /*
            The user_set_y_axis function is used to set the min and max of the graph y axis based on user inputted variables
            */
            user_set_y_axis(){
                let y_min = this.y_axis[0];
                let y_max = this.y_axis[1];
                this.chartOptions.vAxis.viewWindow.min = y_min;
                this.chartOptions.vAxis.viewWindow.max = y_max;
            },
            /*
            The graph_selected function is used to invoke the initDraw function will add a new div to the screen,
            and allow users to select a section of the graph to zoom in on.
            */
            graph_selected: async function () {
                await this.sleep(250);
                this.initDraw(document.getElementById("box_selector"));
            },
            /*
            The pop_prev_selection function is used to reset the graph zoom, added and selected using the box selector
            */
            pop_prev_selection(){
                let prev_selection =  this.previous_selections.pop();
                if(prev_selection != null) {
                    this.x_axis = [prev_selection['xmin'], prev_selection['xmax']];
                    this.y_axis = [prev_selection['ymin'], prev_selection['ymax']];
                    this.user_set_x_axis();
                    this.user_set_y_axis();
                }
            },
            /*
            The helper function sleep is used to sleep a thread
            */
            sleep: function(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            /*
            The data_tooltip function is used to generate the html for the tooltip of a data point
            params:
            -------
            x: The x value of the curve point
            y: The y value of the curve point
            date: The date associated with a data point
            name: The name of the dataset the data point belongs to
            cluster: The name of the cluster the data point belongs to, if any
            -------
            return:
            -------
            The html of the tooltip.
            -------
            */
            data_tooltip(x,y,date,name,cluster=null){
                if(x != null && y != null && date != null) {
                    if(!Number.isFinite(x)){
                        x = Number.parseFloat(x);
                    }
                    if(!Number.isFinite(y)){
                        y = Number.parseFloat(y);
                    }
                    x = x.toFixed(2);
                    y = y.toFixed(2);
                    if(date != '') {
                        date = (new Date(date)).toLocaleDateString();
                    }
                }
                else{
                    x = 0;
                    y = 0;
                    date = '00/00/0000';
                }
                let tooltip_html = '<div style="padding: 5px">';
                // tooltip_html += '<span class="tooltip_title" style="text-align: center;">'+name+'</span>';
                tooltip_html += '<table>';
                tooltip_html += '<tr><td class="tooltip_title" style="text-align: center" colspan="2">'+name+'</td></tr>';
                tooltip_html += '<tr><td class="tooltip_x"><b>X:</b></td><td class="tooltip_x" style="text-align: center">'+x+'</td></tr>';
                tooltip_html += '<tr><td class="tooltip_y"><b>Y:</b></td><td class="tooltip_y" style="text-align: center">'+y+'</td></tr>';
                tooltip_html += '<tr><td class="tooltip_date" style="text-align: center" colspan="2">'+date+'</td></tr>';
                if(cluster != null){
                    tooltip_html += '<tr><td class="tooltip_date" style="text-align: center" colspan="2">'+cluster+'</td></tr>';
                }
                tooltip_html += '</table>';
                tooltip_html += '</div>';
                return tooltip_html;
            },
            /*
            The curve_tooltip function is used to generate the html for the tooltip of a curve
            params:
            -------
            x: The x value of the curve point
            y: The y value of the curve point
            rms: The error in the curve
            -------
            return:
            -------
            The html of the tooltip.
            -------
             */
            curve_tooltip(x,y,rms){
                x = x.toFixed(2);
                y = y.toFixed(2);
                rms = rms.toFixed(2);
                let tooltip_html = '<div style="padding: 5px">';
                tooltip_html += '<table>';
                tooltip_html += '<tr><td class="tooltip_x">X:</td><td class="tooltip_x" style="text-align: center">'+x+'</td><td></td><td></td></tr>';
                tooltip_html += '<tr><td class="tooltip_y">Y:</td><td class="tooltip_y" style="text-align: center">'+y+'</td><td>+/-</td><td>'+rms+'</td></tr>';
                tooltip_html += '</table>';
                tooltip_html += '</div>';
                return tooltip_html;
            },
            initDraw(canvas) {
                function setMousePosition(e) {
                    var ev = e || window.event; //Moz || IE
                    if (ev.pageX) { //Moz
                        mouse.x = ev.pageX - canvas.getBoundingClientRect().left;
                        mouse.y = ev.pageY - canvas.getBoundingClientRect().top;
                    } else if (ev.clientX) { //IE
                        mouse.x = ev.clientX + document.body.scrollLeft;
                        mouse.y = ev.clientY + document.body.scrollTop;
                    }
                }
                function axisUpdate(){
                    vm.previous_selections.push({'xmin': vm.x_axis[0],'ymin': vm.y_axis[0],'xmax': vm.x_axis[1],'ymax': vm.y_axis[1]});
                    let view = element;
                    let ele = canvas;
                    let x_diff = vm.x_axis[1] - vm.x_axis[0];
                    let y_diff = vm.y_axis[1] - vm.y_axis[0];
                    let px_tick_x = x_diff / ele.clientWidth;
                    let px_tick_y = y_diff /ele.clientHeight;
                    let x_min = vm.x_axis[0] + (view.style.left.replace("px","") * px_tick_x);
                    let x_max = x_min + (view.clientWidth * px_tick_x);
                    let y_min = vm.y_axis[0] + ((ele.clientHeight - (parseInt(view.style.top.replace("px","")) + view.clientHeight))  * px_tick_y);
                    let y_max = y_min + (view.clientHeight * px_tick_y);
                    view.style.setProperty('width', 0 + "px");
                    view.style.setProperty('height', 0 + "px");
                    vm.x_axis = [x_min,x_max];
                    vm.y_axis = [y_min,y_max];
                    vm.box_selector = false;
                    vm.user_set_x_axis();
                    vm.user_set_y_axis();
                }

                var mouse = {
                    x: 0,
                    y: 0,
                    startX: 0,
                    startY: 0
                };
                var element = null;
                let vm = this;

                canvas.onmousemove = function (e) {
                    setMousePosition(e);
                    if (element !== null) {
                        element.style.width = Math.abs(mouse.x - mouse.startX) + 'px';
                        element.style.height = Math.abs(mouse.y - mouse.startY) + 'px';
                        element.style.left = (mouse.x - mouse.startX < 0) ? mouse.x + 'px' : mouse.startX + 'px';
                        element.style.top = (mouse.y - mouse.startY < 0) ? mouse.y + 'px' : mouse.startY + 'px';
                    }
                }

                canvas.onclick = function () {
                    if (element !== null) {
                        canvas.style.cursor = "default";
                        axisUpdate();
                        canvas.removeChild(element);
                        element = null;
                        console.log("finsihed.");
                        vm.box_selector = false;
                    } else {
                        console.log("begun.");
                        mouse.startX = mouse.x;
                        mouse.startY = mouse.y;
                        element = document.createElement('div');
                        element.className = 'rectangle';
                        element.style.left = mouse.x + 'px';
                        element.style.top = mouse.y + 'px';
                        canvas.appendChild(element);
                        canvas.style.cursor = "crosshair";
                    }
                }
            }
        }
    }
</script>

<style>
    .rectangle {
        border: 2px solid #0070ff !important;
        position: absolute !important;
        background-color: #0070ff10;
    }
</style>