import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import Run from '../views/Run.vue'
import Install from '../views/Install.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/run',
    name: 'run',
    component: Run,
    meta: {
          requiresAuth: true,
          title: 'Docma'
    }
  },
  {
      path: '/demo',
      name: 'demo',
      component: Run,
      meta: {
          requiresAuth: true,
          title: 'Docma'
      }
  },
  {
      path: '/install',
      name: 'install',
      component: Install,
      meta: {
          requiresAuth: true,
          title: 'Docma'
      }
  },
  {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
          title: 'Docma'
      }
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);

    document.title = to.meta.title;

    if(requiresAuth && !currentUser){
        next('Login');
    }
    else{
        next();
    }
});

export default router
