<!--
Created on Sat Feb 1 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the Dashboard vue component of the SmartCurve Application.
This component is responsible for displaying the statistics of the various datasets as well as providing
functionality for adding new datasets, getting to the filter bar for each dataset and switching which dataset is
in the graph view.
-->
<template>
    <v-card class="stats_card pa-0" style="width: 100%; height: 100%;">
        <v-simple-table fixed-header>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <th class="stat_title text-left">Name</th>
                        <th class="stat_title text-left"># Data Points</th>
                        <th class="stat_title text-left"># Outliers</th>
                        <th class="stat_title text-left">Curve Strength</th>
                        <th>
                            <v-tooltip bottom open-delay="1000">
                                <template v-slot:activator="{on, attrs}">
                                    <v-icon @click="add_new_dataset" color="primary" v-bind="attrs" v-on="on" :disabled="datasets.length > 4">mdi-plus</v-icon>
                                </template>
                                <span class="systemTooltips">Add New DataSet</span>
                            </v-tooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in datasets" :key="item.key">
                        <th><v-radio-group v-model="selectedDataName" @change="update_selected_dataset(item.name)"><v-radio color="primary" :value="item.name"></v-radio></v-radio-group></th>
                        <th class="stat_name">{{item.name}}</th>
                        <th class="stat_value">{{item.numPoints}}</th>
                        <th class="stat_value">{{item.numOutliers}}</th>
                        <th class="stat_value">{{item.curveStrength}}% <v-icon class="ml-3" v-if="item.curveStrength >= 70" color="#0A8C4E">mdi-thumb-up-outline</v-icon><v-icon class="ml-3" v-if="item.curveStrength <= 39" color="#B41D1D">mdi-thumb-down-outline</v-icon><v-icon class="ml-3" v-if="item.curveStrength > 39 && item.curveStrength < 70" color="#f3a815">mdi-thumbs-up-down</v-icon></th>
                        <th>
                            <v-tooltip bottom open-delay="1000">
                                <template v-slot:activator="{on, attrs}">
                                    <v-icon @click="select_dataset_to_filter(item.name)" color="primary" v-bind="attrs" v-on="on">mdi-filter-outline</v-icon>
                                </template>
                                <span class="systemTooltips">Filter DataSet</span>
                            </v-tooltip>
                        </th>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-overlay v-model="filter_overlay">
            <filter-bar style="height: 75vh; width: 50vw;" :filterController="filterController" :apiToken="apiToken" :data_obj="curData" :name_error="data_name_update_error" v-on:PackagedDataDataUpdate="PackagedDataUpdateEmit" v-on:PackagedDataFilterUpdate="PackagedDataUpdate" v-on:deleteDataset="delete_current_dataset" v-on:closeFilterBar="close_dataset_filters" v-on:newDataName="update_current_dataset_name"></filter-bar>
        </v-overlay>
    </v-card>
</template>

<script>
    import DataController from '@/javascript/DataController.js';
    import FilterBar from '@/components/FilterBar2.vue'
    export default {
        name: "Dashboard",
        components: {
            FilterBar
        },
        props: [
            'filterController',
            'apiToken'
        ],
        data(){
            return {
                datasets: [],
                dataController: null,
                filter_overlay: false,
                curData: null,
                selectedDataName: "",
                data_name_update_error: false
            }
        },
        mounted() {
            this.dataController = new DataController();
            this.add_new_dataset();
        },
        methods: {
            /*
            The add_new_dataset function is used to add a new empty dataset to the data controller and dashboard
             */
            add_new_dataset: function(){
                if(this.datasets.length < 5) {
                    let dataName = this.dataController.get_next_name();
                    this.dataController.add_data_object(dataName);
                    this.update_selected_dataset(dataName);
                    this.rebuild_data_sets_arr();
                }
            },
            /*
            The delete current dataset function is used to delete the currently selected dataset from the data controller and dashboard
             */
            delete_current_dataset: function(){
                this.close_dataset_filters();
                this.dataController.delete_data_obj_by_name(this.selectedDataName);
                this.rebuild_data_sets_arr();
                if(this.datasets.length > 0) {
                    let dataName = this.datasets[0].name;
                    this.update_selected_dataset(dataName);
                }
                else{
                    this.dataController.curDataName = null;
                    this.emitData();
                }
            },
            /*
            The rebuild_data_sets_arr function is used to get a list of datasets from the data controller and build a list of their names, id, and stats
             */
            rebuild_data_sets_arr: function(){
                let dataset_names = this.dataController.get_dataset_names();
                this.datasets = [];
                for(let i = 0; i < dataset_names.length; i ++){
                    let name = dataset_names[i];
                    let data = this.dataController.get_data_obj_by_name(name);
                    let curveStrength = data.curveStrength.toLocaleString();
                    let numberDataPoints = data.getLength().toLocaleString();
                    let numberOutliers = data.getNumOutliers().toLocaleString();
                    this.datasets.push({'name': name, 'curveStrength': curveStrength, 'numPoints': numberDataPoints, 'numOutliers': numberOutliers, 'key': i})
                }
            },
            /*
            The update_selected_dataset function is used to update which dataset is the currently selected for viewing and editing
             */
            update_selected_dataset(dataName){
                this.selectedDataName = dataName;
                this.dataController.curDataName = dataName;
                this.curData = this.dataController.get_data_obj_by_name(dataName);
                this.emitData();
            },
            /*
            The select_dataset_to_filter function is used to set the dataset to filter
             */
            select_dataset_to_filter(dataName){
                this.update_selected_dataset(dataName);
                this.filter_overlay = true;
            },
            /*
            The close_dataset_filters function is used to close the filter bar
             */
            close_dataset_filters(){
                this.data_name_update_error = false;
                this.filter_overlay = false;
            },
            /*
            The packagedDataUpdateEmit function is used to update the selected data object in the
            data contoller, as well as emit the updates back to the home page.
            This is generally used when a new filter configuration is run in the filter bar.
            */
            PackagedDataUpdateEmit: function(data) {
                let dataName = this.selectedDataName;
                this.dataController.update_data_object(dataName, data);
                this.rebuild_data_sets_arr();
                this.emitData();
            },
            /*
            The PackagedDataUpdate function is used to update the selected data object in the
            data contoller.
            This is used when a filter is changed in the filter bar but not run
            */
            PackagedDataUpdate: function(data){
                let dataName = this.selectedDataName;
                this.dataController.update_data_object(dataName, data);
            },
            /*
            The emitData function is used to emit the data controller object, which has been altered, back to the Run page
            to be distributed to the other components in the application
             */
            emitData: function(){
                this.$emit('emitPackagedData', this.dataController);
            },
            /*
            The update current_dataset_name is used to update the name of the currently selected dataset. The name
            is recieved from the filter bar.
             */
            update_current_dataset_name: async function(name){
                this.close_dataset_filters();
                let complete = this.dataController.update_data_obj_name(this.selectedDataName, name);
                this.rebuild_data_sets_arr();
                if(complete){
                    this.select_dataset_to_filter(name);
                }
                else{
                    this.select_dataset_to_filter(this.selectedDataName);
                    this.data_name_update_error = true;
                }
            },
            /*
            A helper function
             */
            sleep: function(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }
    }
</script>

<style scoped>

</style>