/*
Created on Sat March 05 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the script for building, running, and interpretting a query on the docma servers,
for demo purposes
*/
// import sdk from '@datorama/sdk'
import jQuery from 'jquery'
export default class DatoramaQuery {
    constructor() {
        this._queryMap = {};
        this._desiredDimensions = [];
        this._dimensionFilters = [];
        this._measurments = [];
        this._startDate = '1970-01-01';
        this._endDate = '1970-01-01';
    }

    get queryMap() {
        return this._queryMap;
    }

    set startDate(startDate) {
        this._startDate = startDate;
    }

    set endDate(endDate) {
        this._endDate = endDate;
    }

    /*
    The add_dimension_filter_to_query function is used to add a dimension filter to a specific query
    params:
    -------
    dimension: the dimension for the filter
    values: a list of values to filter on
    -------
     */
    add_dimension_filter_to_query(dimension, values) {
        let filter_query = {
            "vals": [],
            "dimension": "",
            "operator": "IN"
        };
        filter_query["vals"] = values;
        filter_query["dimension"] = dimension;
        this._dimensionFilters.push(filter_query);
    }

    /*
    The add_measurment_to_query function is used to add a desired measurement in your query
    params:
    -------
    measurment: The measurment to add to the query
    -------
     */
    add_measurment_to_query(measurment) {
        let measurment_query = {
            "name": ""
        };
        measurment_query["name"] = measurment;
        this._measurments.push(measurment_query);
    }

    /*
    The add_dimension_to_query function is used to add a disered dimension in your query
    params:
    -------
    dimension: The dimension to add to the query
    -------
     */
    add_dimension_to_query(dimension) {
        this._desiredDimensions.push(dimension);
    }

    /*
    The build_query function is used to build a datorama query from the set variables
     */
    build_query() {
        for (let dimension of this._dimensionFilters) {
            this._queryMap[dimension['dimension']] = dimension['vals'];
        }
        this._queryMap['independent_var'] = this._measurments[0]['name'];
        this._queryMap['dependent_var'] = this._measurments[1]['name'];
        this._queryMap['start_date'] = this._startDate;
        this._queryMap['end_date'] = this._endDate;
    }

    /*
    The run_query function is used to run a query and interpret the response
    params:
    -------
    desired_variables: the variables to interpret the query
    -------
    return:
    -------
    the interpreted datorama query
    -------
     */
    async run_query(vars,token) {
        this.build_query();
        console.log(this._queryMap);
        let data = await this.datorama_query_api(token);
        console.log(data);
        return data['data'];
    }

    /*
    The datorama_query_api function is used to run the query endpoint of the datorama api
     */
    datorama_query_api(token) {
        let query = this._queryMap;
        console.log(token)
        return new Promise(function (resolve, reject) {
            jQuery.ajax({
                url: 'https://api.docmasmartcurve.ca/demo/getData?token=' +token ,
                type: 'POST',
                data: JSON.stringify(query),
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("Content-Type", 'application/json')
                },
                success: function (response) {
                    resolve(response)
                },
                error: function(error){
                    reject(error)
                }
            })
        });
    }
}
