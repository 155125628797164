/*
Created on Sat February 11 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the script for creating an object of the filters and variables that a user sets and will be displayed in the filter bar.
This also contains the values for each filter
*/
import * as datoramaAPI from './DatoramaApiConnect';
import DatoramaQuery from './DatoramaQuery'
import FilterTree from './FilterTree'
import Graph from './Graph'
export default class FilterController {
    constructor(){
        this._loading = true;
        this._filters = {};
        this._variables = {};
        this._demo = false;
        this._filterTree = null;
        this._filterGraph = null;
    }
    /*
    The setup_new_controller function is used to setup a new filter_controller from a dictionary.
    This is used to build a filter controller from the dictionary built in setup mode.
    params:
    -------
    setup_dictionary: {
        'Filters':
        'Variables':
    },
    filterTree: filterTree Objects
    -------
    return:
    -------
    -------
     */
    async setup_new_controller(setup_dictionary,filterTree){
        this._filters = await this.build_filters_list(setup_dictionary['Filters']);
        this._variables = await this.build_variables_list(setup_dictionary['Variables']);
        this._filterTree = filterTree;
        this.finish_loading();
    }
    /*
    The setup_from_configuration function is used to setup a new filter_controller from an outputted filter_controller configuration.
    This is used in RUN and DEMO mode.
    params:
    -------
    configuration_dictionary: a filter_controller config dict, can be obtained from calling get_json_representation
    -------
    return:
    -------
    -------
     */
    async setup_from_configuration(configuration_dictionary){
        this._filters = configuration_dictionary['filter_map'];
        this._variables = configuration_dictionary['variable_map'];
        if(Object.keys(configuration_dictionary).includes('demo_mode')){
            this._demo = configuration_dictionary['demo_mode'];
        }
        console.log(configuration_dictionary['filter_tree'])
        let filterTree = new FilterTree(configuration_dictionary['filter_tree']['order']);
        filterTree.buildTreeFromJsonRepresentation(configuration_dictionary['filter_tree']['tree']);
        this._filterTree = filterTree;
        this.buildGraph(configuration_dictionary['filter_map']);
        this.finish_loading();
    }
    async getFilterSystemNames(chossen_dimensions){
        let datorama_dimensions = await datoramaAPI.getDimensions();
        let datorama_dimension_info = null;
        let datorama_dimension_system_name = null;
        let system_names = [];
        for(let dimension in chossen_dimensions){
            datorama_dimension_info = datorama_dimensions[chossen_dimensions[dimension]];
            datorama_dimension_system_name = datorama_dimension_info['systemName'];
            system_names.push(datorama_dimension_system_name);
        }
        return system_names;
    }
    async buildGraph(){
        let system_names = ['Advertiser','Channel'];
        let queryResponse = [
            ['BATL','Discovery Ads'],
            ['BATL','Social'],
            ['BATL','SEM'],
            ['BATL','Social Display Image'],
            ['CAA','Social'],
            ['CAA','Youtube'],
            ['Philip Morris International','Display'],
            ['Philip Morris International','Video'],
            ['TD Bank (Multicultural)','Display'],
            ['CREA','Display'],
            ['CREA','Connected TV'],
            ['CREA','Native Video'],
            ['CREA','Pinterest'],
            ['CREA','Social Display Video'],
            ['CREA','Video'],
            ['Velo','Display'],
            ['Vuse','Display'],
            ['Weiser Lock','Display'],
            ['Weiser Lock','SEM'],
            ['Weiser Lock','Social'],
            ['Weston Foods','Pinterest'],
            ['IQOS','Display'],
            ['IQOS','Video'],
            ['Takeda','Display'],
            ['Healthy Web Inc. dba Fullscript','Video'],
            ['Healthy Web Inc. dba Fullscript','Display']
        ];
        this._filterGraph = new Graph(system_names);
        this._filterGraph.buildGraphFrom2DvalArray(queryResponse);
    }
    /*
    The build_filters_list function is used to build the filters for the filter controller off of datorama dimension names
    params:
    -------
    chossen_dimensions: a list of datorama dimension names
    -------
    return:
    -------
    a map of the filter information, names, info, and values
    -------
     */
    async build_filters_list(chossen_dimensions){
        let datorama_dimensions = await datoramaAPI.getDimensions();
        let datorama_dimension_display_name = "";
        let datorama_dimension_system_name = "";
        let datorama_dimension_info = null;
        let datorama_dimension_filter_options = [];
        let filter_map = {};
        for(let dimension in chossen_dimensions){
            datorama_dimension_info = datorama_dimensions[chossen_dimensions[dimension]];
            datorama_dimension_display_name = datorama_dimension_info['displayName'];
            datorama_dimension_system_name = datorama_dimension_info['systemName'];
            datorama_dimension_filter_options = await this.getDimensionFilterOptions(datorama_dimension_system_name);
            filter_map[dimension] = {'datorama_name': datorama_dimension_display_name, 'datorama_system_name': datorama_dimension_system_name, 'datorama_info': datorama_dimension_info, 'filter_options': datorama_dimension_filter_options};
        }
        return filter_map;
    }
    /*
    The getDimensionFilterOptions function is used to get filter values from a datorama query
    params:
    -------
    dimension: a dimension name
    -------
    return:
    -------
    a list of dimension filter options
    -------
     */
    async getDimensionFilterOptions(dimension){
        let queryd = new DatoramaQuery();
        queryd.startDate = "1999-01-01";
        queryd.endDate = "2021-04-01";
        queryd.add_dimension_to_query(dimension);
        let queryResponse = await queryd.run_query([dimension]);
        return queryResponse;
    }
    /*
    The build_variables_list function is used to build the variables for the filter controller
    params:
    -------
    chossen_variables: A list of datorama measurement names
    -------
    return:
    -------
    the variable map for the filter controller
    -------
     */
    async build_variables_list(chossen_variables){
        let datorama_measurements = await datoramaAPI.getMeasurments();
        let datorama_measurement_display_name = "";
        let datorama_measurement_system_name = "";
        let variable_map = {};
        let x_vars = [];
        for (let x_var of chossen_variables['x_vars']){
            datorama_measurement_display_name = datorama_measurements[x_var]['displayName'];
            datorama_measurement_system_name = datorama_measurements[x_var]['systemName'];
            x_vars.push({'display': datorama_measurement_display_name, 'system': datorama_measurement_system_name});
        }
        let y_vars = [];
        for (let y_var of chossen_variables['y_vars']){
            datorama_measurement_display_name = datorama_measurements[y_var]['displayName'];
            datorama_measurement_system_name = datorama_measurements[y_var]['systemName'];
            y_vars.push({'display': datorama_measurement_display_name, 'system': datorama_measurement_system_name});
        }
        variable_map['independent_variables'] = x_vars;
        variable_map['dependent_variables'] = y_vars;
        return variable_map;
    }
    /*
    The finish loading function is used to take the filter controller out of the loading state
     */
    finish_loading(){
        this._loading = false;
    }
    /*
    The get_json_representation function is used to get a json representation of the filter controller
     */
    get_json_representation(){
        let json = {};
        json['variable_map'] = this._variables;
        json['filter_map'] = this._filters;
        json['filter_tree'] = this._filterTree.getjsonRepresentation();
        return json
    }
    /*
    Getter functions to retrieve the individual components of the filter controller
     */
    get filters(){
        return this._filters;
    }
    get variables(){
        return this._variables;
    }
    get filterTree() {
        return this._filterTree;
    }
    get filterGraph() {
        return this._filterGraph;
    }
    get demo(){
        return this._demo;
    }
    get loading(){
        return this._loading;
    }
}