<!--
Created on Sat Feb 1 2021
All rights reserved. © Docma Inc., 2021
@author: Taylor M Grant

This is the Filter Bar vue component of the SmartCurve Application.
This component is used setting the various filters for a specified dataset.
-->
<template>
    <v-card class="filter_card pa-1" light>
        <v-container class="ma-0 pa-0" style="height:100%; width: 100%; background-color: white;">
            <!--Setup error bars-->
            <!--Missing Selections Error-->
            <transition name="fade" style="z-index:2;">
                <v-col v-if="name_error" class="ma-0 pa-0 error_bar" sm="12" style="height: 10%; width: 100%; z-index: 2; position: absolute; left: 0; top: 0;">
                    <span class="error_bar_text"><v-icon color='white' style="margin-right: 2%;">mdi-alert-circle-outline</v-icon>Error! Name Already Exists</span>
                </v-col>
            </transition>
            <!--Set the loading circle-->
            <v-row v-if="loading" class="ma-0 pa-0" style="height:80%; width: 100%;" align="center" justify="center">
                <v-col cols="auto" align-self="center">
                    <v-progress-circular indeterminate color="primary" size="200">
                        {{loading_stage}}
                    </v-progress-circular>
                </v-col>
            </v-row>
            <!--Set the title bar-->
            <v-row class="ma-0 pa-1" style="height:5%; width: 100%;" v-if="!loading" >
                <v-col class="pa-1" sm="9" style="height: 100%;"><span class="filter_dashboard_title" style="float: left">Filter Dashboard</span></v-col>
                <v-col class="pa-1" sm="3" style="height: 100%;">
                    <v-tooltip bottom open-delay="2000">
                        <template v-slot:activator="{on, attrs}">
                            <v-icon style="float: right; margin-left: 2%; height: 100%;" @click="close_dashboard" v-bind="attrs" v-on="on" color="primary">mdi-close</v-icon>
                        </template>
                        <span class="systemTooltips">Close</span>
                    </v-tooltip>
                    <v-divider class="filter_divider" style="float: right" vertical></v-divider>
                    <v-tooltip bottom open-delay="1000">
                        <template v-slot:activator="{on, attrs}">
                            <v-icon style="float: right; margin-right: 2%; height: 100%;" @click="deleteDataset" v-bind="attrs" v-on="on" color="primary">mdi-delete-outline</v-icon>
                        </template>
                        <span class="systemTooltips">Delete DataSet</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <!--Divider between titlebar and selectors-->
            <v-divider class="filter_divider mx-3"></v-divider>
            <!--Sets up all the filter and variable selectors-->
            <v-row class="ma-0" style="height:77%; width: 100%; overflow-y: scroll; margin-bottom: 3% !important;" v-if="!loading">
                <!--Sets up the DataSet name text input-->
                <v-col sm="12">
                    <v-text-field class="filter_selector" v-model="data_name" @keyup="nameDeB(data_name)" persistent-hint hint="DataSet Name"></v-text-field>
                </v-col>
                <!--loops through the various filters and generates a selector for each-->
                <h1 class="filter_section_title mx-3">Dataset Filters</h1><br>
                <v-row style="width: 100%;" class="ma-0 pa-0" v-for="(filter, idx) in added_filters" :key="idx">
                    <v-col sm="1">
                        <v-icon v-if="idx==0" color="#0070ff" style="width: 100%; height: 100%;">mdi-numeric-1-circle-outline</v-icon>
                        <v-icon v-if="idx==1" color="#0070ff" style="width: 100%; height: 100%;">mdi-numeric-2-circle-outline</v-icon>
                        <v-icon v-if="idx==2" color="#0070ff" style="width: 100%; height: 100%;">mdi-numeric-3-circle-outline</v-icon>
                    </v-col>
                    <v-col sm="10">
                        <FilterSelector v-if="reset_filters" class="filter_selector" :filter_name="filter.name" :filter_options="filter_options[filter.system]" :selected_filter="selected_filters[filter.name]" v-on:selectionUpdate="update_selected"></FilterSelector>
                    </v-col>
                    <v-col sm="1">
                        <v-icon color="#0070ff" style="width: 100%; height: 100%;" @click="delete_filter(idx)">mdi-delete-outline</v-icon>
                    </v-col>
                </v-row>
                <v-row style="width: 100%;" class="ma-0 pa-0" align="center" justify="center">
                    <v-col cols="auto" class="mx-0 my-3 pa-0">
                        <v-tooltip bottom open-delay="1000">
                            <template v-slot:activator="{on, attrs}">
                                <v-btn fab color="primary" v-bind="attrs" v-on="on" @click="adding_filter = true" :disabled="added_filters.length > 2">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span class="systemTooltips">Add Filter</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-overlay v-model="adding_filter">
                    <v-card style="width: 40vw;">
                        <v-row class="ma-0 pa-0">
                            <v-card-title>Add Dataset Filter</v-card-title>
                            <v-spacer></v-spacer>
                            <v-col cols="1" class="ma-0 pa-0">
                                <v-icon style="width: 100%; height: 100%" @click="adding_filter = false">mdi-close</v-icon>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-select class="filter_selector" v-model="new_filter" :rules="[v => (v || '').length <= 3 || `Max selections made`]" :items="Object.keys(filters).filter(x => !(added_filters.map(y => y.name)).includes(x))" multiple>
                                <template v-slot:item="{item}">
                                    <v-icon class="mr-5" v-if="!new_filter.includes(item)">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon class="mr-5" v-if="new_filter.includes(item) && (new_filter.indexOf(item) + added_filters.length) == 0">mdi-numeric-1-box-outline</v-icon>
                                    <v-icon class="mr-5" v-if="new_filter.includes(item) && (new_filter.indexOf(item) + added_filters.length) == 1">mdi-numeric-2-box-outline</v-icon>
                                    <v-icon class="mr-5" v-if="new_filter.includes(item) && (new_filter.indexOf(item) + added_filters.length) == 2">mdi-numeric-3-box-outline</v-icon>
                                    <span class="filter_selection">{{item}}</span>
                                </template>
                            </v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-row style="width: 100%; height: 100%;" justify="center" align="center">
                                <v-col cols="3" class="ma-0 pa-0">
                                    <v-btn rounded outlined color=primary style="width: 100%; height: 50px;" :disabled="new_filter.length > 3 || added_filters.length > 2 || (new_filter.length + added_filters.length) > 3" @click="add_filter()">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-overlay>
                <!--Sets up a date range picker-->
                <v-col sm="12">
                    <v-dialog v-model="date_range_picker" persistent width="300px">
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field class="filter_selector" v-model="date_range_text" label="Date Range" prepend-icon="mdi-calendar-range" readonly v-bind="attrs" v-on="on" :disabled="demo_mode"></v-text-field>
                        </template>
                        <transition name="fade">
                            <v-col v-if="date_range_picker_error == true" class="ma-0 pa-0 error_bar" sm="12" style="height: 15%; width: 100%;  z-index: 2;">
                                <span class="error_bar_text"><v-icon color='white' style="margin-right: 2%;">mdi-alert-circle-outline</v-icon>Error! Missing Selections</span>
                            </v-col>
                        </transition>
                        <v-date-picker v-model="date_range" range color="primary">
                            <v-row class="pa-0 ma-0" justify="space-around">
                                <v-btn @click="update_selected_date(date_range)"><v-icon>mdi-close</v-icon></v-btn>
                                <v-btn @click="update_selected_date(date_range)">OK</v-btn>
                            </v-row>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <!--Sets up the independant and dependant variable selectors-->
                <h1 class="filter_section_title mx-3">Dataset Variables</h1>
                <v-col sm="12">
                    <v-select class="filter_selector" v-model="selected_independent_var" :items="independent_variables" item-text="display" label="Independent Variable" :rules="[v => !!v || 'Independent Variable is required']" required prepend-icon="mdi-alpha-x-circle-outline"></v-select>
                </v-col>
                <v-col sm="12">
                    <v-select class="filter_selector" v-model="selected_dependent_var" :items="dependent_variables" item-text="display" label="Dependent Variable" :rules="[v => !!v || 'Dependent Variable is required']" required prepend-icon="mdi-alpha-y-circle-outline"></v-select>
                </v-col>
                <!--Cluster Selector-->
                <h1 class="filter_section_title mx-3">Cluster</h1>
                <v-col sm="12">
                    <v-select class="filter_selector" v-model="cluster" :items="Object.keys(filters)" :disabled="true">
                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="cluster='None'">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        None
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <!--Sets up settings for a run -->
                <h1 class="filter_section_title mx-3">Run Settings</h1>
                <v-col sm="12">
                    <v-switch class="filter_selector" v-model="remove_outliers_func" color="primary" label="Remove Outliers"></v-switch>
                    <v-subheader class="filter_selector">Outlier Sensitivity</v-subheader>
                    <v-slider class="filter_selector" v-model="outlier_removal_sensitivity" :disabled="demo_mode || !remove_outliers_func" color="primary" value="5" min="1" max="10" ticks="always" tick-size="4" :tick-labels="[1,,3,,5,,7,,9,]" prepend-icon="mdi-chart-scatter-plot" append-icon="mdi-chart-scatter-plot-hexbin"></v-slider>
                    <v-switch class="filter_selector" v-model="build_smartcurve_func" color="primary" label="Build SmartCurve"></v-switch>
                </v-col>
            </v-row>
            <!--Divider between selectors and actions-->
            <v-divider class="mx-3 filter_divider"></v-divider>
            <!--Sets up the action buttons-->
            <v-row class="ma-0 pa-0" style="height: 15%; width: 100%;" justify="center" align="center">
                <v-col sm="4">
                    <v-tooltip bottom open-delay="1000">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn rounded outlined @click="run_manager()" :disabled="loading" v-bind="attrs" v-on="on" color="#0070ff" style="max-height: 100%; width: 100%">
                                <v-icon color="primary">mdi-play</v-icon>
                            </v-btn>
                        </template>
                        <span class="systemTooltips">Update Dataset</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import FilterSelector from './FilterSelector'
    import DatoramaQuery from '@/javascript/DocmaQuery.js'
    import Data from '@/javascript/data.js'
    import * as smartCurveApi from '@/javascript/SmartCurveApiCaller.js'
    import * as smartCurveDemoApi from '@/javascript/Mocks/smartCurveMocks.js'
    export default {
        name: "FilterBar",
        components: {
            FilterSelector
        },
        props: [
            'filterController',
            'data_obj',
            'name_error',
            'apiToken'
        ],
        data(){
            return{
                cluster: "None",
                filters: {},
                filter_options: {},
                selected_filters: {},
                independent_variables: [],
                dependent_variables: [],
                selected_independent_var: null,
                selected_dependent_var: null,
                date_range: [],
                date_range_picker: false,
                date_range_picker_error: false,
                data: null,
                loading: false,
                loading_stage: "",
                num_loading_calls: 0,
                data_name: "",
                nameDeB: this.updateDataNameDebounce(this.emitNewDataName,500),
                build_smartcurve_func: true,
                remove_outliers_func: true,
                outlier_removal_sensitivity: 5,
                demo_mode: false,
                reset_filters: true,
                //filters
                added_filters: [],
                adding_filter: false,
                new_filter: []
            }
        },
        watch:{
            date_range: function(){
                this.update_selected_vars();
            },
            name_error: function(){
                if(this.name_error == true){
                    this.data_name = this.data_obj.dataName;
                    this.kill_error(2000,'name');
                }
            }
        },
        computed: {
            date_range_text: function() {
                return this.date_range[0] + ' - ' + this.date_range[1];
            }
        },
        async mounted(){
            while(this.filterController == null){
                await this.sleep(200);
            }
            this.filters = this.filterController.filters;
            for(let filter of Object.keys(this.filters)){
                this.selected_filters[filter] = [];
                this.filter_options[this.filters[filter]['datorama_system_name']] = this.filters[filter]['filter_options'];
            }
            this.added_filters = [];
            this.independent_variables = this.filterController.variables['independent_variables'];
            this.dependent_variables = this.filterController.variables['dependent_variables'];
            this.selected_independent_var = this.independent_variables[0]['system'];
            this.selected_dependent_var = this.dependent_variables[0]['system'];
            let today_str = '2021-01-24';
            let yesterday_str = '2020-01-01';
            this.date_range.push(yesterday_str);
            this.date_range.push(today_str);
            this.cluster = 'None';
            if(this.data_obj != null){
                this.data = this.data_obj;
                this.set_selected_vars();
            }
            else{
                this.update_selected_vars();
            }
            if(this.filterController.demo){
                this.demo_mode = this.filterController.demo;
            }
            // this.update_avaialable_filter_vals();
        },
        methods: {
            /*
            set_selected_vars is used to set the variables and filters of the filter bar equal to the values in the given data object
             */
            set_selected_vars(){
                this.loading = true;
                let filter_dict = {};
                let filter_order = [];
                let date_range = [];
                let x_var = "";
                let y_var = "";
                let cluster = "None";
                if(this.data != null) {
                    filter_dict = this.data.filterDict;
                    filter_order = this.data.filterOrder;
                    date_range = this.data.dateRange;
                    x_var = this.data.independentVar;
                    y_var = this.data.dependentVar;
                    cluster = this.data.cluster;
                    this.data_name = this.data.dataName;
                }
                let filters = Object.keys(filter_dict);
                for(let filter of Object.keys(this.filters)){
                    if(filters.includes(filter)) {
                        this.selected_filters[filter] = filter_dict[filter];
                    }
                    else{
                        this.selected_filters[filter] = [];
                    }
                }
                this.added_filters = [];
                for(let added_filter of filter_order){
                    if(Object.keys(this.filters).includes(added_filter.name)){
                        this.added_filters.push(added_filter);
                    }
                }
                if(date_range.length == 2){
                    this.date_range = date_range;
                }
                else{
                    let today = new Date();
                    let today_str = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
                    let yesterday_str = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + (today.getDate() - 2)).slice(-2);
                    this.date_range = [yesterday_str, today_str];
                }
                if(x_var != null){
                    for(let v of this.independent_variables) {
                        if(v.display == x_var) {
                            this.selected_independent_var = v.system;
                        }
                    }
                }
                if(y_var != null){
                    for(let v of this.dependent_variables) {
                        if(v.display == y_var) {
                            this.selected_dependent_var = v.system;
                        }
                    }
                }
                if(cluster != null){
                    this.cluster = cluster;
                }
                if(this.data.outliersRemoved){
                    this.remove_outliers_func = true;
                }
                else{
                    this.remove_outliers_func = false;
                }
                if(this.data.smartCurveBuilt){
                    this.build_smartcurve_func = true;
                }
                else{
                    this.build_smartcurve_func = false;
                }
                this.outlier_removal_sensitivity = this.data.outlierSensitivity;
                this.loading = false;
                this.update_selected_vars();
                this.update_available_filter_vals();
            },
            /*
            update_selected function is used to update chossen filters
            params:
            -------
            value_map: {filer_name: filer_value} a map where the key is the name of the filter and the value is a list of selected filter values
            -------
             */
            update_selected(value_map){
                let filter_name = Object.keys(value_map)[0];
                let filter_value = value_map[filter_name];
                this.selected_filters[filter_name] = filter_value;
                this.update_selected_vars();
                this.update_available_filter_vals();
            },
            /*
            */
            add_filter(){
                this.reset_filters = false;
                for(let filter of Object.keys(this.filters)){
                    for(let f of this.new_filter) {
                        if (filter == f) {
                            this.added_filters.push({
                                'system': this.filters[filter]['datorama_system_name'],
                                'name': filter
                            })
                        }
                    }
                }
                this.new_filter = [];
                this.reset_filters = true;
                this.update_selected_vars();
                this.update_available_filter_vals();
            },
            /*
            */
            delete_filter(filter_idx){
                console.log(filter_idx);
                this.selected_filters[this.added_filters[filter_idx].name] = [];
                this.added_filters.splice(filter_idx,1);
                this.update_selected_vars();
                this.update_available_filter_vals();
            },
            /*
            */
            update_available_filter_vals(){
                let graph = this.filterController.filterGraph;
                let filters = Object.keys(this.selected_filters);
                let selected_filters = new Array(this.added_filters.length).fill(null);
                let num_selected = 0;
                for(let f of filters){
                    if(this.selected_filters[f].length > 0){
                        let idx = this.added_filters.findIndex(x => x.name === f);
                        selected_filters[idx] = f;
                        num_selected += 1;
                    }
                }
                let values = [];
                let filter = null;
                let skip_values = [];
                let skip_filter = null;
                if(num_selected == 1 && this.added_filters.length == 2){
                    if(selected_filters[0] != null) {
                        values = graph.get_values_over(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system);
                        filter = this.added_filters[1].system;
                    }
                }
                else if(num_selected == 2 && this.added_filters.length == 2){
                    values = graph.get_values_over(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system);
                    filter = this.added_filters[1].system;
                }
                else if(num_selected == 1 && this.added_filters.length == 3){
                    if(selected_filters[0] != null) {
                        values = graph.get_values_over(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system);
                        filter = this.added_filters[1].system;
                        skip_values = graph.get_values_skip(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system, values, this.added_filters[2].system);
                        skip_filter = this.added_filters[2].system;
                    }
                    else if(selected_filters[1] != null){
                        skip_values = graph.get_values_over(this.added_filters[1].system, this.selected_filters[selected_filters[1]], this.added_filters[2].system);
                        skip_filter = this.added_filters[2].system;
                    }
                }
                else if(num_selected == 2 && this.added_filters.length == 3){
                    if(selected_filters[0] != null && selected_filters[1] != null){
                        values = graph.get_values_over(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system);
                        filter = this.added_filters[1].system;
                        skip_values = graph.get_values_skip(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system, this.selected_filters[selected_filters[1]], this.added_filters[2].system);
                        skip_filter = this.added_filters[2].system;
                    }
                }
                else if(num_selected == 0) {
                    for(let idx in this.added_filters){
                        if(idx == 0){
                            this.filter_options[this.added_filters[idx].system] = this.filters[this.added_filters[idx].name]['filter_options'];
                        }
                        else{
                            this.filter_options[this.added_filters[idx].system] = [];
                        }
                    }
                }
                else if(num_selected == 3 && this.added_filters.length == 3){
                    if(selected_filters[0] != null && selected_filters[1] != null){
                        values = graph.get_values_over(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system);
                        filter = this.added_filters[1].system;
                        skip_values = graph.get_values_skip(this.added_filters[0].system, this.selected_filters[selected_filters[0]], this.added_filters[1].system, this.selected_filters[selected_filters[1]], this.added_filters[2].system);
                        skip_filter = this.added_filters[2].system;
                    }
                }
                this.reset_filters = false;
                if(filter != null){
                    console.log(filter);
                    console.log(values);
                    this.filter_options[filter] = values;
                }
                if(skip_filter != null){
                    console.log(skip_filter);
                    console.log(skip_values);
                    this.filter_options[skip_filter] = skip_values;
                }
                this.reset_filters = true;
            },
            /*
            update_selected_date is used to to check weather or not the date_range update is valid
             */
            update_selected_date(date_range){
                if(typeof date_range == 'object' && date_range.length == 2){
                    this.date_range_picker = false;
                }
                else{
                    this.date_range_picker_error = true;
                    this.kill_error(2000,'date_range_picker');
                }
            },
            /*
             The run manager function is used to build a dataset and run it through the docma servers.
             It will determine if data needs to be generated, outliers need to be removed, or smartcurve is required to be built
            */
            async run_manager(){
                this.set_loading(true);
                console.log('Generate Data');
                this.loading_stage = "Loading Data...";
                await this.generate_data();
                if(this.remove_outliers_func){
                    console.log('Remove Outliers');
                    this.loading_stage = "Removing Outliers...";
                    await this.remove_outliers();
                    this.data.outliersRemoved = true;
                }
                else{
                    this.data.outliersRemoved = false;
                }
                if(this.build_smartcurve_func){
                    console.log('Build Smart Curve');
                    this.loading_stage = "Calculating SmartCurve...";
                    await this.regression_line();
                    this.data.smartCurveBuilt = true;
                }
                else{
                    this.data.smartCurveBuilt = false;
                }
                this.loading_stage = "";
                this.set_loading(false);
            },
            /*
            the generate_data function is used to call the datorama_query api to obtain the raw data that matches the filters
            and measurments given
             */
            async generate_data(){
                this.set_loading(true);
                let query = new DatoramaQuery();
                for (let filter of Object.keys(this.selected_filters)) {
                    if (this.selected_filters[filter].length > 0) {
                        let dimension_name = this.filters[filter]['datorama_system_name'];
                        query.add_dimension_filter_to_query(dimension_name, this.selected_filters[filter]);
                    }
                }
                query.add_dimension_to_query('DATE_DAY');
                let cluster_var = null;
                if(this.cluster != 'None'){
                    query.add_dimension_to_query(this.filters[this.cluster]['datorama_system_name']);
                    cluster_var = this.filters[this.cluster]['datorama_system_name'];
                    console.log(cluster_var)
                }
                console.log(this.selected_dependent_var)
                query.add_measurment_to_query(this.selected_independent_var);
                query.add_measurment_to_query(this.selected_dependent_var);
                query.startDate = this.date_range[0];
                query.endDate = this.date_range[1];
                let interpretedData = null;
                if(this.cluster != 'None'){
                    interpretedData = await query.run_query([this.selected_independent_var, this.selected_dependent_var, 'DATE_DAY', cluster_var], this.apiToken);
                }
                else {
                    interpretedData = await query.run_query([this.selected_independent_var, this.selected_dependent_var, 'DATE_DAY'], this.apiToken);
                }
                this.data = new Data(interpretedData);
                this.data.filterDict = Object.assign({},this.selected_filters);
                this.data.filterOrder = Object.assign([],this.added_filters);
                this.data.independentVar = this.selected_independent_var;
                this.data.dependentVar = this.selected_dependent_var;
                this.data.dateRange = this.date_range;
                this.data.dataName = this.data_name;
                this.data.filtersUpdatedSinceDataUpdate = false;
                this.set_loading(false);
            },
            /*
            The remove_outliers function is used to call the docma servers and send the quiered data to obtain a list of outliers
            from the dataset
             */
            async remove_outliers(){
                this.set_loading(true);
                let data_message = this.data.format_json_output();
                data_message['sensitivity'] = this.outlier_removal_sensitivity;
                let outlier_response = {'outliers': []};
                if(!this.demo_mode) {
                    // let token = await datoramaApi.getJWTToken();
                    outlier_response = await smartCurveApi.remove_outliers_demo(data_message, this.apiToken).catch((err) => {
                        console.log(err);
                        return {'data':[],'outliers':[]};
                    });
                }
                else{
                    outlier_response['outliers'] = smartCurveDemoApi.remove_outliers(this.data.filterDict);
                }
                this.data.outlierSensitivity = this.outlier_removal_sensitivity;
                this.data.setOutliers(outlier_response['outliers']);
                this.set_loading(false);
            },
            /*
            The regression_line function is used to call the docma servers and send the quiered data to obtain the regression and prediction lines
            for the dataset
             */
            async regression_line(){
                this.set_loading(true);
                if(this.data.xyData.length > -1) {
                    let data_message = this.data.format_json_output();
                    let smartCurve = {'data': {'regressionLine': {'x_data': [], 'y_data': []}, 'futurePredicition': {'x_data': [], 'y_data': []}, 'rms': 1}};
                    if(!this.demo_mode) {
                        // let token = await datoramaApi.getJWTToken();
                        smartCurve = await smartCurveApi.smart_curve_demo(data_message, this.apiToken).catch((err) => {
                            console.log(err);
                            return {'data': {'regressionLine': {'x_data':[],'y_data':[]}, 'futurePredicition': {'x_data':[],'y_data':[]}, 'rms': 'N/A'}}
                        });
                    }
                    else{
                        smartCurve = smartCurveDemoApi.get_smartcurve(this.data.filterDict);
                    }
                    this.data.setRegressionLine(smartCurve['data']['regressionLine']);
                    this.data.setPredictionLine(smartCurve['data']['futurePredicition']);
                    this.data.error = smartCurve['data']['rms'];
                }
                this.set_loading(false);
            },
            /*
            The set_loading function is used to put the filter bar in to a loading state.
            if it is already in loading, it will extend the amount of the time the tool is in loading
            params:
            -------
            state: the loading state
            -------
            return:
            -------
            -------
             */
            set_loading(state){
                if(state == this.loading){
                    this.num_loading_calls += 1;
                }
                else {
                    if(this.num_loading_calls > 0){
                        this.num_loading_calls -= 1;
                    }
                    else {
                        this.loading = state;
                        if(this.loading == false){
                            this.EmitData();
                        }
                    }
                }
            },
            /*
            The EmitData function is used to emit the data object back to the dashboard
             */
            EmitData: function(){
                this.$emit('PackagedDataDataUpdate', this.data);
            },
            /*
            The update_selected_vars function is used to update the data object variables from the filter bar variables
             */
            update_selected_vars(){
                if(this.data == null){
                    this.data = new Data();
                }
                this.data.filterDict = Object.assign({},this.selected_filters);
                this.data.filterOrder = Object.assign([],this.added_filters);
                this.data.independentVar = this.selected_independent_var;
                this.data.dependentVar = this.selected_dependent_var;
                this.data.dateRange = this.date_range;
                this.$emit('PackagedDataFilterUpdate', this.data);
            },
            /*
            The close_dashboard function is used to send a signal to the dashboard to close the filter bar
             */
            close_dashboard(){
                this.$emit('closeFilterBar')
            },
            /*
            The deleteDataset function is used to send a signal to the dashboard to delete the dataset
             */
            deleteDataset(){
                this.$emit('deleteDataset')
            },
            /*
            The emitNewDataName function is used to send a signal to update the dataname
             */
            emitNewDataName(name){
                this.$emit('newDataName',name);
            },
            /*
            THe updateDataNameDebounce function is used to set a debounce for when a new dataname is updated
             */
            updateDataNameDebounce(callback,delay){
                let timeout;
                return (...args) => {
                    const context = this;
                    clearTimeout(timeout);
                    timeout = setTimeout(() => callback.apply(context,args),delay);
                };
            },
            sleep: function(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            /*
            The kill_error function is used to kill a specified error
            params:
            -------
            ms: time before killing error
            error_ref: The error to kill
            -------
            return:
            -------
            -------
             */
            kill_error: async function(ms, error_ref){
                await this.sleep(ms);
                switch (error_ref){
                    case 'date_range_picker':
                        this.date_range_picker_error = false;
                        break;
                    case 'name':
                        this.name_error = false;
                        break;
                }
            }
        }
    }
</script>

<style scoped>
</style>